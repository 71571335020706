<template>
    <div>
        <van-popup v-model="show" round position="bottom" @click-overlay="close">
            <div class="content">
                <i class="iconfont icon-cuowu_shibai close-icon" @click="close"></i>
                <div class="info">
                    <img class="logo" :src="logoUrl" mode="aspectFit" />
                    <div class="tips">{{ $t('downApp.SaveVersionTips') }}</div>
                </div>
                <div class="btns">
                    <div @click="close">{{ $t('lang.cancel') }}</div>
                    <div @click="onDownApp">{{ $t('downApp.Install') }}</div>
                </div>
            </div>
        </van-popup>
        <tipsPopup ref="Tips1Popup" :tips="$t('downApp.downAppSuccTips')"></tipsPopup>
        <tipsPopup ref="Tips2Popup" :tips="$t('downApp.downAppSuccTips2')" @close="toHome" @confirm="toHome"></tipsPopup>
        <tipsPopup ref="Tips3Popup" :tips="$t('downApp.NotInDevice')" @close="toHome" @confirm="toHome"></tipsPopup>
    </div>
</template>

<script>
import tipsPopup from './tipsPopup'
let savedPwaPrompt888 = null
export default {
    components: { tipsPopup },
    data() {
        return {
            show: false,
            isShowTipsPopup: false,
            isShowTips2Popup: false,
            isShowTips3Popup: false,
            savedPwaPrompt: null
        }
    },
    props: {
        isToHome: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        logoUrl() {
            return this.$store.getters.sysConfig?.homeLogoUrl
        }
    },
    mounted() {
        try {
            this.$nextTick(() => {
                window.addEventListener('beforeinstallprompt', function (e) {
                    console.log(savedPwaPrompt888, 'this.savedPwaPrompt666====')
                    // 阻止默认提示弹出
                    e.preventDefault()
                    // 把事件存起来
                    savedPwaPrompt888 = e
                    console.log(savedPwaPrompt888, 'this.savedPwaPrompt8888====')
                })
            })
        } catch {
            console.log('error-pwa-不支持？不显示？')
        }
    },
    methods: {
        open() {
            const that = this
            that.show = true
        },
        close() {
            this.show = false
        },
        onDownApp() {
            const that = this
            if ('serviceWorker' in navigator) {
                try {
                    savedPwaPrompt888.prompt()
                    savedPwaPrompt888.userChoice.then(choiceResult => {
                        if (choiceResult.outcome === 'accepted') {
                            that.close()
                            // this.isShowTipsPopup = true
                            // uni.reLaunch({
                            // 	url: '/pages/index/index'
                            // })
                        } else {
                            console.log('用户拒绝安装PWA--可打印')
                        }
                    })
                } catch (e) {
                    that.close()
                    that.$refs.Tips2Popup.open()
                    console.log(e, 'error-pwa-不支持？不显示？')
                    // pwaIconShow.value = false
                }
            } else {
                that.$refs.Tips3Popup.open()
            }
        },
        toHome() {
            if (!this.isToHome) return
            if (this.$route.path.indexOf('/Home') !== -1) {
                window.location.reload()
            } else {
                this.onNavTo({ url: '/Home', replace: 1 })
            }
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .van-popup--bottom {
    overflow: visible;
}

.content {
    padding: .28rem;
    padding-bottom: 0;
    padding-top: .38rem;
    box-sizing: border-box;
    width: 100%;
    background: #15161c;
    border-radius: .2rem .2rem 0 0;

    .close-icon {
        width: 35px;
        height: 35px;
        vertical-align: middle;
        position: absolute;
        right: .2rem;
        top: -45px;
        cursor: pointer;
        font-size: .62rem;
        color: #fff;
    }

    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            width: 1.3rem;
            height: 1.3rem;
            vertical-align: middle;
        }

        .tips {
            width: calc(100% - 150upx);
            color: #fff;
            font-size: .28rem;
            line-height: .38rem;
        }
    }

    .btns {
        height: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &>div {
            width: 1.8rem;
            height: .8rem;
            border-radius: .1rem;
            background: #286aff;
            color: #fff;
            margin-left: .2rem;
            text-align: center;
            line-height: .78rem;
            cursor: pointer;
        }
    }
}
</style>
