import Cookies from 'js-cookie'
const state = {
    language: localStorage.getItem('language') || 'en_US',
    userSetlanguage: localStorage.getItem('userSetlanguage'),
    sysConfig: null,
    Pixel: Cookies.get('Pixel') || '',
    TaId: Cookies.get('TaId') || '',
    ClickId: Cookies.get('ClickId') || '',

    isShowDownAppNav: localStorage.getItem('isShowDownAppNav') || 1,
    isSaveVersionDesktop: 1
}

const mutations = {
    SET_LANGUAGE: (state, lang) => {
        state.language = lang
        localStorage.setItem('language', lang)
    },
    SET_USERSETLANGUAGE: (state, lang) => {
        state.userSetlanguage = lang
        localStorage.setItem('userSetlanguage', lang)
    },
    SET_SYSCONFIG: (state, config) => {
        state.sysConfig = config
    },
    SET_PIXEL: (state, info) => {
        const Key = 'Pixel'
        state.Pixel = info
        Cookies.set(Key, info)
    },
    SET_TAID: (state, info) => {
        const Key = 'TaId'
        state.TaId = info
        Cookies.set(Key, info)
    },
    SET_CLICKID: (state, info) => {
        const Key = 'ClickId'
        state.ClickId = info
        Cookies.set(Key, info)
    },
    SET_ISSHOWDOWNAPPNAV: (state, num) => {
        state.isShowDownAppNav = num
        localStorage.setItem('isShowDownAppNav', num)
    },
    SET_ISSAVEVERSIONDESKTOP: (state, num) => {
        state.isSaveVersionDesktop = num
    }
}

const actions = {
    setLanguage({ commit }, lang) {
        commit('SET_LANGUAGE', lang)
    },
    setUserSetLanguage({ commit }, lang) {
        commit('SET_USERSETLANGUAGE', lang)
    },
    setSysConfig({ commit }, config) {
        commit('SET_SYSCONFIG', config)
    },
    setPixel({ commit }, data) {
        commit('SET_PIXEL', data)
    },
    setTaId({ commit }, data) {
        commit('SET_TAID', data)
    },
    setClickId({ commit }, data) {
        commit('SET_CLICKID', data)
    },
    setIsShowDownAppNav({ commit }, data) {
        commit('SET_ISSHOWDOWNAPPNAV', data)
    },
    setIsSaveVersionDesktop({ commit }, data) {
        commit('SET_ISSAVEVERSIONDESKTOP', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
