<template>
    <div class="popup" :class="classObj" @click="showTypeList = false">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <div class="header">
                    <div>{{ $t("rebate.title") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="main_content">
                        <div class="partner_box main_box">
                            <div class="time-area">{{ $t("share.startTime") }}：{{ shareData && shareData.startDay }} - {{
                                $t("share.endTime") }}：{{ shareData && shareData.endDay }}</div>
                            <div class="Total-reward-amount">
                                <div>{{ $t("share.TotalRewardAmount") }}</div>
                                <div>{{ CurrencyType }}{{ shareData && shareData.partnerAllRewardsBouns }}</div>
                            </div>
                            <div class="table-title">{{ $t("share.Part1Blogger") }}</div>
                            <div class="table">
                                <div class="table-th">
                                    <div>{{ $t("share.level") }}</div>
                                    <div>{{ $t("share.Amount") + '/' + $t("share.NumberOfPeople") }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.FirstRewardAmount") }}</div>
                                    <div>{{ CurrencyType }}{{ shareData && shareData.oneTeamMemberRewardsAmt }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.FirstNumberOfPeople") }}</div>
                                    <div>{{ shareData && shareData.oneTeamMemberValidNumber }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.FirstRechargeAmount") }}</div>
                                    <div>{{ CurrencyType }}{{ shareData && shareData.oneTeamMemberRechargeAmt }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.FirstRewardRatio") }}</div>
                                    <div>{{ shareData && shareData.oneTeamMemberRewardsScale }}%</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.FirstWithdrawalAmount") }}</div>
                                    <div>{{ CurrencyType }}{{ shareData && shareData.oneTeamMemberWithdrawAmt }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.FirstPaymentDiff") }}</div>
                                    <div>{{ CurrencyType }}{{ shareData && shareData.oneTeamMemberDiff }}</div>
                                </div>
                            </div>
                            <div class="table-title">{{ $t("share.Part2Partners") }}</div>
                            <div class="table">
                                <div class="table-th">
                                    <div>{{ $t("share.level") }}</div>
                                    <div>{{ $t("share.Amount") + '/' + $t("share.NumberOfPeople") }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.FirstEffectiveNumber") }}</div>
                                    <div>{{ shareData && shareData.oneTeamPartnerValidNumber }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.SecondNumberOfPeople") }}</div>
                                    <div>{{ shareData && shareData.twoTeamMemberValidNumber }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.FirstPartnersRewardRatio") }}</div>
                                    <div>{{ shareData && shareData.oneTeamPartnerRewardsScale }}%</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.SecondEffectiveRechargeAmount") }}</div>
                                    <div>{{ CurrencyType }}{{ shareData && shareData.twoTeamMemberValidRechargeAmt }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.SecondEffectiveWithdrawalAmount") }}</div>
                                    <div>{{ CurrencyType }}{{ shareData && shareData.twoTeamMemberValidWithdrawAmt }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.SecondRechargeAmount") }}</div>
                                    <div>{{ CurrencyType }}{{ shareData && shareData.twoTeamMemberRechargeAmt }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.SecondWithdrawalAmount") }}</div>
                                    <div>{{ CurrencyType }}{{ shareData && shareData.twoTeamMemberWithdrawAmt }}</div>
                                </div>
                                <div class="table-tr">
                                    <div>{{ $t("share.FirstPartnersPaymentDiff") }}</div>
                                    <div>{{ CurrencyType }}{{ shareData && shareData.partnerDiff }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="partner_rules">
                            <div class="partner_rules_tips1">{{ $t("share.partnerRulesTips1_1") + (eventData &&
                                eventData.fractionalFlowRatio) + '%' + $t("share.partnerRulesTips1_2") }}</div>
                            <div class="partner_rules_tips2">
                                <span>{{ $t("share.partnerRulesTips2_1") }}</span>
                                <span v-if="eventData && eventData.rewardCycle == 'dailyReward'">{{
                                    $t("share.partnerRulesTips2_2") }}</span>
                                <span v-if="eventData && eventData.rewardCycle == 'weeklyReward'">{{
                                    $t("share.partnerRulesTips2_3") }}</span>
                                <span v-if="eventData && eventData.rewardCycle == 'monthlyReward'">{{
                                    $t("share.partnerRulesTips2_4") }}</span>
                                <span v-if="eventData && eventData.inviteConditions == 'recharge'"
                                    class="partner_rules_tips2">{{ $t("share.partnerRulesTips2_5") }}</span>
                                <span v-if="eventData && eventData.inviteConditions == 'betting'"
                                    class="partner_rules_tips2">{{ $t("share.partnerRulesTips2_6") + (eventData &&
                                        eventData.grandTotalRecharge) + $t("share.partnerRulesTips2_7") + (eventData &&
                                            eventData.grandTotalBetting) + $t("share.partnerRulesTips2_8") }}</span>
                            </div>
                            <div class="table">
                                <div class="table-th">
                                    <div style="border-right: 1px solid #A9ACAB;">{{ $t("share.ReachStandard") }}</div>
                                    <div style="border: 0;">{{ $t("share.RewardRatio") }}</div>
                                </div>
                                <div class="table-tr"
                                    v-for="(item, index) in eventData && eventData.partnerModelDetailVoList" :key="index">
                                    <div>{{ item.validNumber }}</div>
                                    <div>{{ item.rewardsRatio }}%</div>
                                </div>
                            </div>
                        </div>
                        <div class="intro">
                            <div class="title">{{ $t('rebate.EventIntro') }}</div>
                            <div class="desc" v-html="eventData && eventData.eventContent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
// import { Toast, Dialog } from 'vant'
import { mapState } from 'vuex'
import { getPartnerInfo } from '@/api/share'
export default {
    name: 'PartnerPopup',
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        eventId() {
            return this.$store.state.user.PartnerEventId
        },
        show: {
            get() {
                return this.$store.getters.isShowPartnerPopup
            },
            set(val) {
                return val
            }
        }
    },
    data() {
        return {
            shareData: {},
            eventData: {}
        }
    },
    created() { },
    methods: {
        open() {
            this.getPartnerInfo()
        },
        close() {
            this.$store.dispatch('user/setIsShowPartnerPopup', false)
        },
        getPartnerInfo() {
            getPartnerInfo({ id: this.eventId }).then((res) => {
                if (!res) return
                if (res.code) return
                const D = res.result
                this.shareData = D.value1
                this.eventData = D.value0
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {

        .van-popup {
            &.van-popup--center {
                width: 88.6% !important;
                height: max-content !important;
                max-width: 9rem !important;
                min-height: 8.6rem !important;
                max-height: 64.5% !important;
            }

            .content {
                width: 100% !important;
                padding-bottom: .4rem;

                .header {
                    height: .94rem;
                    padding: 0 .28rem;

                    &>div:first-child {
                        font-size: .3rem;
                    }

                    .close {
                        font-size: .3rem;
                    }
                }

                .box {
                    padding: .3rem .3rem 0;

                    .main_content {}
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 20px 20px 0;
                overflow-y: scroll;

                .main_content {

                    .intro {
                        border: .02rem solid $border-color;
                        font-size: .2rem;
                        padding: .2rem;
                        text-align: left;
                        color: #A9ACAB;
                        .title {
                            font-size: .28rem;
                            margin-bottom: .2rem;
                            font-weight: bold;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.partner_box {
    padding: .02rem 0;
    padding-bottom: .22rem;
    background: $bg-box-color;
    border-radius: .24rem;

    .time-area {
        line-height: 1rem;
        color: #fff;
        font-size: .32rem;
        text-align: center;
        line-height: .32rem;
    }

    .Total-reward-amount {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &>div:first-child {
            font-size: .24rem;
            // line-height: .3rem;
            color: #C9CDCC;
        }

        &>div:last-child {
            font-size: .42rem;
            font-weight: bold;
            line-height: .52rem;
            color: transparent;
            background: linear-gradient(80deg, #F3DD00 0%, #F6C900 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .Partner-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .18rem;

        &>div {
            width: 33.33333%;
            text-align: center;
            font-size: .26rem;
            color: #C9CDCC;

            &>div:last-child {
                font-size: .42rem;
                font-weight: bold;
                line-height: .76rem;
                color: transparent;
                background: linear-gradient(80deg, #F3DD00 0%, #F6C900 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    .table-title {
        text-align: left;
        color: #fff;
        font-size: .28rem;
        line-height: .78rem;
        margin-top: .1rem;
    }
}

.table {
    border: 1px solid #A9ACAB;
    border-radius: .08rem;
    background: rgba(40, 52, 75, 0.25);
    margin-bottom: .12rem;

    &>div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #A9ACAB;

        &:last-child {
            border: 0;
        }

        &.table-th {
            &>div {
                color: #FFC480 !important;
                font-size: .26rem;
                padding: .1rem .12rem;
                font-weight: bold;

                &:first-child {
                    border-right: 0;
                    color: #A9ACAB;
                }

                &:last-child {
                    border-left: 1px solid #A9ACAB;
                }
            }
        }

        &>div {
            width: 50%;
            padding: .08rem .12rem;
            text-align: center;
            color: #fff;
            box-sizing: content-box;
            line-height: .3rem;

            &:first-child {
                border-right: 1px solid #A9ACAB;
                color: #A9ACAB;
            }
        }
    }
}

.partner_rules {
    width: 100%;
    padding: .2rem 0 0;
    text-align: left;

    .partner_rules_tips1 {
        color: #fff;
        font-size: .3rem;
        line-height: .3rem;
        padding: .2rem 0;
    }

    .partner_rules_tips2 {
        color: #fff;
        font-size: .24rem;
        line-height: .4rem;
        padding: .2rem 0;
        background: linear-gradient(0deg, #FF940A 0%, #FAC988 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: .38rem;
        font-weight: bold;
    }
}</style>
