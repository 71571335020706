// import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'

const state = {
    token: getToken() || '',
    rememberMe: localStorage.getItem('RememberMe') || '',
    matterNumber: 0,
    userInfo: {},
    isShowLoginPopup: false,
    loginType: 1, // 登录2 注册1
    invitCode: Cookies.get('invitCode') || '',
    invitCodeNum: 0, // 邀请码是否进入过注册页 1是 0否

    isShowDepositPopup: false,
    isShowWithdrawPopup: false,
    isShowBankSetPopup: false,
    isShowMyProfilePopup: false,
    isShowSettingPopup: false,
    isShowBetHistoryPopup: false,
    isShowTransactionPopup: false,
    isShowRedPacketPopup: false,
    isShowDownAppPopup: false,
    isShowCheckInPopup: false,
    isShowPendingPopup: false,
    isShowTurntablePopup: false,
    isShowLossSupportPopup: false,
    isShowRebatePopup: false,
    isShowWinRankListPopup: false,
    isShowPinduodouPopup: false,
    isShowTreasureChestPopup: false,
    WinRankListEventId: '',
    LossSupportEventId: '',
    RebateEventId: '',
    turntableEventId: '',
    checkInEventId: '',
    redPacketEventId: '',
    pinduoduoEventId: '',
    TreasureChestEventId: '',
    isShowDepositTotalPopup: false,
    depositTotalPopupEventId: '',
    isShowDepositRepeatPopup: false,
    depositRepeatPopupEventId: '',
    isShowDepositRepeatUsdtPopup: false,
    depositRepeatPopupUsdtEventId: '',
    lastPopupDate: localStorage.getItem('lastPopupDate') || '',
    isShowPartnerPopup: false,
    PartnerEventId: ''
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
        setToken(token)
    },
    REMOVE_TOKEN: (state, token) => {
        removeToken()
    },
    SET_REMEMBERINFO: (state, info) => {
        state.rememberMe = JSON.stringify(info)
        localStorage.setItem('RememberMe', state.rememberMe)
    },
    SET_MATTERNUMBER: (state, num) => {
        state.matterNumber = num
    },
    SET_USERINFO: (state, info) => {
        state.userInfo = info
    },
    SET_ISSHOWLOGINPOPUP: (state, show) => {
        state.isShowLoginPopup = show
    },
    SET_INVITCODE: (state, Code) => {
        state.invitCode = Code
        Cookies.set('invitCode', Code)
    },
    SET_INVITCODENUM: (state, Num) => {
        state.invitCodeNum = Num
    },
    CHANGE_LOGINTYPE: (state, type) => {
        state.loginType = type
    },
    SET_ISSHOWDEPOSITPOPUP: (state, show) => {
        state.isShowDepositPopup = show
    },
    SET_ISSHOWWITHDRAWPOPUP: (state, show) => {
        state.isShowWithdrawPopup = show
    },
    SET_ISSHOWBANKSETPOPUP: (state, show) => {
        state.isShowBankSetPopup = show
    },
    SET_ISSHOWMYPROFILEPOPUP: (state, show) => {
        state.isShowMyProfilePopup = show
    },
    SET_ISSHOWSETTINGPOPUP: (state, show) => {
        state.isShowSettingPopup = show
    },
    SET_ISSHOWBETHISTORYPOPUP: (state, show) => {
        state.isShowBetHistoryPopup = show
    },
    SET_ISSHOWTRANSACTIONPOPUP: (state, show) => {
        state.isShowTransactionPopup = show
    },
    SET_ISSHOWREDPACKETPOPUP: (state, data) => {
        state.isShowRedPacketPopup = data.show
        state.redPacketEventId = data.id
    },
    SET_ISSHOWDOWNAPPPOPUP: (state, show) => {
        state.isShowDownAppPopup = show
    },
    SET_ISSHOWCHECKINPOPUP: (state, data) => {
        state.isShowCheckInPopup = data.show
        state.checkInEventId = data.id
    },
    SET_ISSHOWPENDINGPOPUP: (state, show) => {
        state.isShowPendingPopup = show
    },
    SET_ISSHOWTURNTABLEPOPUP: (state, data) => {
        state.isShowTurntablePopup = data.show
        state.turntableEventId = data.id
    },
    SET_ISSHOWLOSSSUPPORTPOPUP: (state, data) => {
        state.isShowLossSupportPopup = data.show
        state.LossSupportEventId = data.id
    },
    SET_ISSHOWREBATEPOPUP: (state, data) => {
        state.isShowRebatePopup = data.show
        state.RebateEventId = data.id
    },
    SET_ISSHOWWINRANKLISTPOPUP: (state, data) => {
        state.isShowWinRankListPopup = data.show
        state.WinRankListEventId = data.id
    },
    SET_ISSHOWPINDUODUOPOPUP: (state, data) => {
        state.isShowPinduodouPopup = data.show
        state.pinduoduoEventId = data.id
    },
    SET_ISSHOWTREASURECHESTPOPUP: (state, data) => {
        state.isShowTreasureChestPopup = data.show
        state.TreasureChestEventId = data.id
    },
    SET_ISSHOWDEPOSITTOTALPOPUP: (state, data) => {
        state.isShowDepositTotalPopup = data.show
        state.depositTotalPopupEventId = data.id
    },
    SET_ISSHOWDEPOSITREPEATPOPUP: (state, data) => {
        state.isShowDepositRepeatPopup = data.show
        state.depositRepeatPopupEventId = data.id
    },
    SET_ISSHOWDEPOSITREPEATUSDTPOPUP: (state, data) => {
        state.isShowDepositRepeatUsdtPopup = data.show
        state.depositRepeatPopupUsdtEventId = data.id
    },
    SET_LASTPOPUPDATE: (state, date) => {
        state.lastPopupDate = date
        localStorage.setItem('lastPopupDate', date)
    },
    SET_ISSHOWPARTNERPOPUP: (state, data) => {
        state.isShowPartnerPopup = data.show
        state.PartnerEventId = data.id
    }
}

const actions = {
    setMatterNumber({ commit }, data) {
        return new Promise(resolve => {
            commit('SET_MATTERNUMBER', data)
            resolve()
        })
    },
    saveUserInfo({ commit }, uInfo) {
        commit('SET_USERINFO', uInfo)
    },
    setRememberMe({ commit }, form) {
        commit('SET_REMEMBERINFO', form)
    },
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('REMOVE_TOKEN', '')
            commit('SET_USERINFO', null)
            resolve()
        })
    },
    setInvitCode({ commit }, Code) {
        commit('SET_INVITCODE', Code)
    },
    setInvitCodeNum({ commit }, Num) {
        commit('SET_INVITCODENUM', Num)
    },
    setIsShowLoginPopup({ commit }, show) {
        commit('SET_ISSHOWLOGINPOPUP', show)
    },
    changeLoginType({ commit }, type) {
        commit('CHANGE_LOGINTYPE', type)
    },
    setIsShowDepositPopup({ commit }, show) {
        commit('SET_ISSHOWDEPOSITPOPUP', show)
    },
    setIsShowWithdrawPopup({ commit }, show) {
        commit('SET_ISSHOWWITHDRAWPOPUP', show)
    },
    setIsShowBankSetPopup({ commit }, show) {
        commit('SET_ISSHOWBANKSETPOPUP', show)
    },
    setIsShowMyProfilePopup({ commit }, show) {
        commit('SET_ISSHOWMYPROFILEPOPUP', show)
    },
    setIsShowSettingPopup({ commit }, show) {
        commit('SET_ISSHOWSETTINGPOPUP', show)
    },
    setIsShowBetHistoryPopup({ commit }, show) {
        commit('SET_ISSHOWBETHISTORYPOPUP', show)
    },
    setIsShowTransactionPopup({ commit }, show) {
        commit('SET_ISSHOWTRANSACTIONPOPUP', show)
    },
    setIsShowRedPacketPopup({ commit }, data) {
        commit('SET_ISSHOWREDPACKETPOPUP', data)
    },
    setIsShowDownAppPopup({ commit }, show) {
        commit('SET_ISSHOWDOWNAPPPOPUP', show)
    },
    setIsShowCheckInPopup({ commit }, data) {
        commit('SET_ISSHOWCHECKINPOPUP', data)
    },
    setIsShowPendingPopup({ commit }, show) {
        commit('SET_ISSHOWPENDINGPOPUP', show)
    },
    setIsShowTurntablePopup({ commit }, data) {
        commit('SET_ISSHOWTURNTABLEPOPUP', data)
    },
    setIsShowLossSupportPopup({ commit }, data) {
        commit('SET_ISSHOWLOSSSUPPORTPOPUP', data)
    },
    setIsShowRebatePopup({ commit }, data) {
        commit('SET_ISSHOWREBATEPOPUP', data)
    },
    setIsShowWinRankListPopup({ commit }, data) {
        commit('SET_ISSHOWWINRANKLISTPOPUP', data)
    },
    setIsShowPinduoduoPopup({ commit }, data) {
        commit('SET_ISSHOWPINDUODUOPOPUP', data)
    },
    setIsShowTreasureChestPopup({ commit }, data) {
        commit('SET_ISSHOWTREASURECHESTPOPUP', data)
    },
    setIsShowDepositTotalPopup({ commit }, data) {
        commit('SET_ISSHOWDEPOSITTOTALPOPUP', data)
    },
    setIsShowDepositRepeatPopup({ commit }, data) {
        commit('SET_ISSHOWDEPOSITREPEATPOPUP', data)
    },
    setIsShowDepositRepeatUsdtPopup({ commit }, data) {
        commit('SET_ISSHOWDEPOSITREPEATUSDTPOPUP', data)
    },
    setLastPopupDate({ commit }, date) {
        commit('SET_LASTPOPUPDATE', date)
    },
    setIsShowPartnerPopup({ commit }, data) {
        commit('SET_ISSHOWPARTNERPOPUP', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
