export default {
    lang: {
        LoadMore: 'Load more',
        AllLoaded: 'All loaded',
        ChangeLanguage: 'Change Language',
        ChangePassword: 'Change PassWord',
        copySuccess: 'Replicating Success',
        copyError: 'Copy Failed',
        CopyEmptyTips: 'The copied content cannot be empty',
        empty: 'Empty here',
        tips: 'Tips',
        cancel: 'Cancel',
        confirm: 'Confirm',
        more: 'More',
        isCloseGame: 'Do you want to close the game?',
        back: 'Back',
        Claim: 'Claim',
        Loading: 'Loading',
        Play: 'Play',
        Download: 'Download',
        NoData: 'Oops! No data yet!',
        NoMore: 'No More',
        CurrentlyPage: 'Currently on this page',
        keywordPlaceholder: 'Please enter the name of the game you want to search for!'
    },
    tabbar: {
        Lobby: 'Home',
        Earn: 'Share',
        Game: 'Game',
        Event: 'Event',
        Promotion: 'Promotion',
        Account: 'Profile',
        Recharge: 'Recharge',
        serve: 'Service',
        Deposit: 'Deposit',
        Affiliate: 'Affiliate'
    },
    home: {
        Download: 'Download',
        DownloadTips: 'Download the app and get ',
        Search: 'Search',
        Popular: 'Popular',
        Recent: 'Recent',
        Favorite: 'Favorite',
        Providers: 'Providers',
        CollectionSucc: 'Collection successful',
        CollectionCanceled: 'Collection canceled',
        MORE: 'MORE',
        Top: 'Top',
        greatBonus: 'Great Bonus For Deposit',
        pullRefresh: 'Pull down to refresh...',
        releaseRefresh: 'Release to refresh...',
        loading: 'loading...'
    },
    login: {
        Login: 'Login',
        SignIn: 'Sign In',
        SignUp: 'Sign Up',
        RememberMe: 'Remember me',
        ForgetPassword: 'Forget password?',
        LoginTips: 'To visit this site, make sure you are over 18 and agree?',
        TermsOfService: 'Terms of Service',
        jumpRegister: 'Jump to registration',
        JumpLogin: 'Jump to login',
        noAccountTips: "Don't have an account?",
        hasAccountTips: 'Do you have an account?',
        Email: 'Email',
        VerificationCode: 'Verification code',
        Send: 'Send',
        RestPassword: 'Reset password',
        passwordTips: 'Password(6-16)',
        passwordTips2: 'Enter password again',
        password: 'Password',
        phone: 'Phone number',
        ReferralCode: 'Referral Code',
        CreateAccount: 'Create account',
        PleaseEnterYourPhoneNumber: 'Please enter your phone number',
        PleaseEnterYourPassword: 'Please enter your password',
        ThePasswordMustContain6To16Characters: 'The password must contain 6 to 16 characters',
        InconsistentPassword: 'The password do not match',
        PleaseEnterInvitationCode: 'Please enter invitation code',
        PleaseEnterEmail: 'Please enter your email account',
        PleaseEnterEmailFormat: 'Please enter the correct email format',
        day: 'day',
        also: 'also',
        downTime: 'Date from launch',
        changePwd: 'Change password',
        RechargeNow: 'Recharge Now',
        PlayGame: 'Registration successful! Play a game~',
        serviceTips: 'Please agree to the terms of service first',
        SuccessfullyClaimed: 'Successfully claimed',
        SuccessfullyClaimedTips1: '',
        SuccessfullyClaimedTips2: ' bonus will be distributed to the account',

        Account: 'Account',
        PleaseEnterAccount: 'Please enter Account',
        AccountFormat: 'Error in account format,6-16 characters, supports English/numbers',
        PhoneFormat: 'The format of the phone number you entered is incorrect',
        PleaseEnterPassword: 'Please Enter Password',
        PasswordFormat: '8-16 Characters. Must include at least lowercase letters/uppercase letters/numbers',
        ConfirmPassword: 'Confirm password',
        ConfirmPwdAgain: 'Confirm password again',
        InvitationCode: 'Invitation Code',
        PleaseEnteryourInvitationCode: 'Please enter your Invitation Code',
        OverAgeAgree: 'I am over 18 years old, read and accept',
        PleaseAgreeOverAge: 'First, check user agreement',
        RememberYouraccountPassword: 'Remember your account password',
        SelectCountry: 'Language',

        Reminder: 'Reminder',
        WantLogOutAccount: 'Do you want to log out of your account?',
        ConfirmExit: 'Confirm exit',
        Cancel: 'Cancel',

        Strength: 'Strength',
        sixteenDigits: '8-16 bits',
        UpperCaseletters: 'Upper case letters',
        LowerCaseLetters: 'Lower case letters',
        Numbers: 'Numbers',

        UNLOCK: 'UNLOCK',
        ENDLESSBENEFITS: 'ENDLESS    BENEFITS',
        loginTips1: 'Generous bonuses are ready! join us,find your own treasures',
        loginTips2: 'Exclusive VIP Event: Bet and Get Opportunities',
        loginTips3: 'Red envelope, daily regular payment',
        loginTips4: 'The benefits of inviting your friends to obtain treasure chests.',
        loginTips5: 'The benefits of logging in every day',
        loginTips6: 'When you make your first deposit, you can receive a bonus of up to 20%.',
        loginTips7: 'Upgrade to VIP to receive bonuses',
        loginTips8: 'Welcome to ',
        loginTips9: 'BRING you an unprecedented gaming experience',
        accountOrPhone: 'Account / Phone Number'
    },
    logout: {
        SignOut: 'Sign out',
        SignOutTips: 'Explore a world brimming with rewards and thrilling games!'
    },
    signin: {
        signInTitle: 'Daily Sign-in Bonus',
        signInDays: 'Continuously signed in for ',
        days: ' days',
        day: 'DAY',
        claimed: 'Claimed ',
        condition1: 'Required deposit',
        condition2: 'Required bets',
        eventDetails: 'Event Details',
        checkin: 'Check-in',
        CheckIn: 'CHECK IN',
        Today: 'Today',
        Uncompleted: 'UNCOMPLETED',
        Completed: 'COMPLETED',
        Conditions: 'Conditions',
        Rules: 'Rules'
    },
    share: {
        panel: 'Panel',
        FAQ: 'FAQ',
        TUTORIAL: 'TUTORIAL',
        TotalRewards: 'Total Rewards',
        TotalFriends: 'Total Friends',
        inviteFriend: 'INVITE FRIENDS TO EARN',
        signUp: 'Sign Up & Earn',
        Commission: 'Commission',
        InviteVia: 'Invite via:',
        copy: 'Copy',
        link: 'Link',
        code: 'Code',
        or: 'OR',
        Rewards: 'Rewards',
        title1: 'COMMISSION',
        tip1: 'Earn up to',
        tip2: 'commission when your friend bet.',
        title2: 'DEPOSIT CASHBACK',
        tip3: 'Earn',
        tip4: 'when your friend deposit.',
        Details: 'Details',
        UncollectedCommissions: 'Uncollected commissions',
        ExclusiveCommission: 'Exclusive Commission',
        DepositCashback: 'Deposit cashback',
        Claim: 'Claim',
        TodayTeamData: 'Today team data',
        TotalTeamData: 'Total team data',
        SignUp: 'Sign Up',
        FirstDeposit: 'First Deposit',
        ValidBet: 'Valid bet',
        LiveRewards: 'Live Rewards',
        SendTotalCommission: 'Send total commission',
        rules: 'Rules',
        ruleInfo: 'You can make money by referring friends to and earn up to commission rewards！',
        Recommended: 'Recommended',
        Month: 'Month',
        UnlimitedSubordinates: 'Unlimited development of subordinates',
        planTitle: 'LEARN MORE ABOUT OUR AFFILIATE PROGRAM',
        planInfo: 'If you have a large audience and followers. We have special conditions for you to customize your referral program!',
        all: 'All',
        Direct: 'Direct',
        Indirect: 'Indirect',
        TodayTeamIntro1: 'Sign up:Number of users who signed up today',
        TodayTeamIntro2: 'First Deposit:Number of first deposit users today',
        TodayTeamIntro3: 'Valid bets:Valid betting for today’s team',
        TotalTeamIntro1: 'Sign up: Teams Total number of sign ups',
        TotalTeamIntro2: 'First Deposit: Total number of first-time depositors',
        TotalTeamIntro3: 'Valid bets:The team’s total effective bet amount',
        calculationRules: 'Commission calculation rules',
        rewardRules: 'Commission reward rules',
        ExtendRewardsBouns: 'Extend Rewards Bouns',
        GameBettingRewardsBouns: 'GameBetting Rewards Bouns',
        RechargeRewardsBouns: 'Recharge Rewards Bouns',
        Tier: 'Tier',
        intro1: 'Sign up:Number of users who signed up today',
        intro2: 'First Deposit:Number of first deposit users today',
        intro3: 'Valid bets:Valid betting for today’s team',
        intro4: 'Sign up: Teams Total number of sign ups',
        intro5: 'First Deposit: Total number of first-time depositors',
        intro6: 'Valid bets:The team’s total effective bet amount',
        rule1: ' Every time your recommended player places a bet, wins or loses, you will receive a different commission percentage.',

        startTime: 'start',
        endTime: 'end',
        TotalRewardAmount: 'Total reward amount:',
        // rules: '',
        level: 'level',
        Amount: 'amount',
        NumberOfPeople: 'Number of people',
        Part1Blogger: 'User rewards',
        FirstRewardAmount: 'First level member reward amount',
        FirstNumberOfPeople: 'Number of valid first level members',
        FirstRewardRatio: 'Reward ratio for first level valid members',
        FirstPaymentDiff: 'Payment difference of first level members',
        FirstRechargeAmount: 'Recharge amount for first tier members',
        FirstWithdrawalAmount: 'First level member withdrawal amount',
        Part2Partners: 'Partner rewards',
        PartnerRewardAmount: 'Partner Reward Amount:',
        PartnerRechargeAmount: 'Partner recharge amount:',
        PartnerWithdrawalAmount: 'Partner withdrawal amount:',
        FirstEffectiveNumber: 'Effective number of first level partners',
        SecondNumberOfPeople: 'Number of second level valid members',
        FirstPartnersRewardRatio: 'Reward ratio for first level partners',
        FirstPartnersRewardAmount: 'Reward amount for first level partners',
        FirstPartnersPaymentDiff: 'Payment difference of first level partner',
        SecondEffectiveRechargeAmount: 'Effective recharge amount for second level members',
        SecondEffectiveWithdrawalAmount: 'Effective withdrawal amount for second level members',
        SecondRechargeAmount: 'Recharge amount for second level members',
        SecondWithdrawalAmount: 'Second level member withdrawal amount',
        partnerRulesTips1_1: 'Improve recommendation rewards and increase the profit sharing of recommenders. Blogger A recommends blogger B, and A enjoys ',
        partnerRulesTips1_2: " of B's profit",
        partnerRulesTips1_3: '',
        partnerRulesTips2_1: 'The partnership rules require settlement ',
        partnerRulesTips2_2: 'once a day. ',
        partnerRulesTips2_3: 'once a week. ',
        partnerRulesTips2_4: 'once a month. ',
        partnerRulesTips2_5: 'Making the first recharge counts as a valid member',
        partnerRulesTips2_6: 'Recharge ',
        partnerRulesTips2_7: ' and place a bet of ',
        partnerRulesTips2_8: ' to be considered a valid member',
        ReachStandard: 'Number of people meeting the standard',
        RewardRatio: 'Reward ratio',
        partner: 'Partner'
    },
    deposit: {
        PaymentMethodsRregion: 'Payment methods for the region',
        DepositMethods: 'Deposit methods',
        Deposit: 'Deposit',
        FirstDeposit: 'First Deposit',
        Details: 'Details',
        Amount: 'Amount',
        Extra: 'Extra',
        noBonus: 'no bonus',
        PleaseEnterDeposit: 'Please enter deposit amount',
        isMinRechargeAmtTips: 'The recharge amount must be greater than the minimum recharge amount',
        isMaxRechargeAmtTips: 'The recharge amount must be less than the maximum recharge amount',
        showBonusTips: 'Do not participate in deposit promotions',
        rewardPercentageTxt1_1: 'Over',
        rewardPercentageTxt1_2: 'plus',
        rewardPercentageTxt1_3: '%',
        rewardPercentageTxt2_1: 'Daily First Fill ',
        rewardPercentageTxt2_2: '+',
        rewardPercentageTxt2_3: '%',
        rewardPercentageTxt3_1: 'Registration first fill ',
        rewardPercentageTxt3_2: '+',
        rewardPercentageTxt3_3: '%',
        Change: 'Change',
        DepositBonus: 'Deposit Bonus',
        DepositAmount: 'Deposit Amount',
        Bonus: 'Bonus',
        DepositTips: 'Platform deposits offer generous rewards, with a specified amount of money deposited each time to receive a bonus.',
        howTitle: 'How to Claim the Deposit Bonus?',
        Step: 'Step',
        Step2Title: 'Claim deposit bonus',
        howTips1: 'Go to deposit. Choose your preferred deposit method to make a deposit.',
        howTips2: 'After the deposit is completed, you will receive the corresponding bonus based on the deposit amount and bonus ratio.',
        TransferDeposit: 'USDT Deposit',
        OnlineDeposit: 'Online Deposit',
        ExchangeRate: 'Exchange rate',
        Tutorial: 'USDT recharge tutorial',
        TransferAmount: 'Transfer amount :',
        TargetAccount: 'Target account :',
        showTips: 'After the system detects your transfer, it will increase your balance',
        transferred: 'I have transferred the money',
        information: 'Transfer information',
        name: 'Payee name :',
        bank: 'bank :'
    },
    DepositDetails: {
        DepositDetails: 'Deposit Details',
        WaitingForPayment: 'Waiting for payment.',
        countDownTxt1: 'Please',
        countDownTxt2: 'complete the deposit',
        ExchangeRate: 'Exchange rate',
        DepositMethod: 'Deposit Method',
        Currency: 'Currency',
        MainnetProtocol: 'Mainnet Protocol (the network channel, very important)',
        PaymentAddress: 'Payment Address',
        CreationTime: 'Creation Time',
        OrderNumber: 'Order Number',
        TransactionRemark: 'Transaction remark',
        cashAddress: 'cash address',
        DepositTips1: "1. Please ensure the correct amount after deducting the withdrawal fee (the correct received amount) to complete the transaction; otherwise, the deposit won't be successful.",
        DepositTips2: "2. Confirm that the mainnet protocol you are transferring/depositing matches the mainnet protocol you have selected. Otherwise, the deposit/transfer won't be successful or will be refunded.",
        DepositTips3: '3. Only supports USDT. Please do not transfer other currencies to this address.',
        cancelBtn: 'cancel an order',
        TransferredBtn: 'I Have Transferred',
        ContinueBtn: 'Continue to Deposit',
        hashAddress: 'Hash address',
        pleasetEnterHashAddress: 'pleaset enter hash address',
        cancelOrderTips: 'Do you want to cancel this payment order?',
        waitPayTips: 'There are currently unpaid orders, would you like to proceed with payment?'
    },
    Withdraw: {
        Withdraw: 'Withdraw',
        BalanceWithdraw: 'Balance Withdraw',
        BonusWithdraw: 'Bonus Withdraw',
        WithidrawLimitPerday: 'Withdrawal limit perday',
        WithdrawTimePerday: 'Withdrawal time perday',
        WithdrawableBalance: 'Withdrawable Balance',
        WithdrawableBonus: 'Remaining bonus',
        WithdrawAmount: 'Withdraw amount',
        ratetxt1: 'I also need to bet',
        ratetxt2: 'to withdraw',
        ratetxt3: ' still require a bet of ',
        ratetxt4: ' to withdraw',
        ChooseYourBankcard: 'Choose your bankcard',
        AddABankCardFirst: 'Add a bankcard first',
        WithdrawalAmount: 'Withdrawal amount',
        disabledWithdraw: 'The current account withdrawal function has been disabled. Please contact customer service!',
        PleaseEnterMiniWithdrawPrice: 'The withdrawal amount cannot be less than the minimum withdrawal amount',
        PleaseEnterWithdraw: 'Please enter withdraw',
        PleaseEnterInteger: 'Please enter an integer for the withdrawal amount',
        PleaseChooseBank: 'Please enter the bank card information first',
        VerifyIdentity: 'Verify identity',
        notInTime1: 'Withdrawal time available:',
        notInTime2: 'System settlement time:',
        notInTime3: 'After the start of the withdrawal, the amount will reach the account within 24 hours of the start of the withdrawal. If you have any questions, please contact our customer support.',
        All: 'All',
        OnlineWithdraw: 'Online Withdraw',
        TransferWithdraw: 'USDT Withdraw',
        USDTPaymentAddress: 'USDT payment address',
        PleaseEnterUSDTPaymentAddress: 'Please enter USDT payment address',
        Fee: 'Fee',
        Receive: 'Receive',
        WithdrawTips1: 'Withdrawal discount: Withdrawals are not available on Fridays, Saturdays, and Sundays. If you want to withdraw funds within these three days, we recommend using USDT. USDT funds will arrive in your account within 10 minutes! If your funds do not reach your account within 10 minutes, please be patient and users must review them one by one. If you have any questions, please contact support.',
        WithdrawTips2: 'Withdrawal reminder: When withdrawing, we recommend using an amount below $10000 so that USDT can reach your account within 5 minutes. If you withdraw money with a credit card, it will be credited to your account within 24 hours!'
    },
    bankset: {
        BankCardDetails: 'Bank Card Details',
        Type: 'Type',
        AccountName: 'Account name',
        NameOfTheAccountHolder: 'Name of the account holder',
        PIXAccount: 'PIX Account',
        InputPIXAccount: 'Input PIX account',
        CPFAccount: 'CPF Account',
        PleaseEnterCPF: 'Please enter CPF',
        WithdrawPassword: 'Withdraw Password',
        InputWithdrawalPassword: 'Enter withdrawal password',
        ConfirmWithdrawalPassword: 'Confirm withdrawal password',
        PleaseEnterWithdrawPassword: 'Please enter withdraw password',
        InputConfirmWithdrawalPassword: 'Enter the confirmation withdrawal password',
        ChooseCertificateType: 'Choose certificate type',
        InputAccountName: 'Enter Account name',
        InputInconsistentWithdrawPassword: 'Inconsistent Withdraw Password input',
        PayeeAccount: 'beneficiary account no',
        BankName: 'Bank name',
        ChooseBank: 'Choose a bank',
        PleaseSelectBank: 'Please select a bank',
        AccountPhone: 'Phone number',
        EnterPhoneNumber: 'enter phone number',
        PleaseEnterPhoneNumber: 'Please enter your phone number',
        email: 'E-mail',
        PleaseEnterEmail: 'Please enter your email address',
        EmailFormat: 'Email format incorrect',
        InputPayeeAccount: 'Please enter the receiving account number',
        PleaseSelectChannelName: 'Please select channel name',
        Save: 'Save',
        PayeeAccountTips1: 'Please enter an 11 digit phone number',
        PayeeAccountTips2: 'Please enter a 16 digit bank card number'
    },
    Notification: {
        Notification: 'Notification',
        news: 'News',
        nomore: 'No more'
    },
    bonusCenter: {
        BonusDetails: 'Bonus Details',
        BonusCategories: 'Bonus Categories',
        WeeklyBonus: 'Weekly Bonus',
        MonthlyBonus: 'Monthly Bonus',
        LevelupBonus: 'Level up Bonus',
        BonusRain: 'Red Packet',
        DepositBonus: 'Deposit Bonus',
        SpecialBonus: 'Special Bonus',
        BonusTransactions: 'Bonus Transactions',
        BonusType: 'Bonus Type',
        ClaimedAmount: 'Claimed Amount',
        Time: 'Time',
        OopsNodatayet: 'Oops! No data yet!',
        TotalBonusClaime: 'Total Bonus Claimed',
        TotalGeneralBonus: 'Activity bonus',
        TotalVIPBonus: 'Total VIP Bonus',
        rechargeBonus: 'Recharge bonus',
        Details: 'Details',
        TotalBonus: 'Total Bonus:',
        Rules: 'Rules',
        Bonusends: 'Bonus ends:',
        DepositNow: 'Deposit Now',
        Generalbonus: 'General bonus',
        Todayevents: 'Today’s events',
        Nextrain: 'Next time',
        CheckIn: 'Check In',
        Counts: 'Counts',
        Deposit: 'Deposit',
        VIPBonus: 'VIP Bonus',
        Levelupbonus: 'Level up bonus',
        XPtoVIP: 'XP to VIP',
        UpgradeBonus: 'Upgrade Bonus',
        NextLevelBonus: 'Next level Bonus',
        ViewVIPClub: 'View VIP Club',
        Wager: 'Wager',
        VIP: 'VIP',
        Unlockendless: 'Unlock endless benefits',
        Generousbonuses: 'Generous bonuses are ready! Join us.Find your own treasures.',
        JoinNow: 'Join Now',
        Upgradebonuses: 'Upgrade VIP to get bonuses',
        Invitefriendss: 'Invite friends to play games and get bonuses',
        Completequest: 'Complete quest to get bonuses',
        Winbigwith: 'Win big with our weekly and monthly bonuses!',
        Discover: 'Discover extraordinary bonus',
        numerousbonuses: 'We offer numerous bonuses to every user. There are new rewards every day and every week.',
        Signbonus: 'Sign up and get bonus',
        LevelupVIP: 'Level up VIP, more rewards!',
        VIPstatusmeans: 'VIP status means more value here. You can quickly build wealth and create a solid financial base byunlocking its perks.',
        Whatget: 'What do VIPs get?',
        Saygoodbye: 'Say goodbye to ordinary gaming, hello to pure luxury.',
        RedeemBonusCode: 'Redeem Bonus Code',
        RedeemCode: 'Redeem Code',
        AllBonuses: 'All bonuses',
        eventManage: 'Activity bonus',
        VIPCycleReward: 'VIP level reward',
        Extend: 'Promotion bonus',
        reliefMoneyEvent: 'Rescue gold activity',
        singInEvent: 'Sign in activity',
        luckyWheelEvent: 'Lucky Wheel Activity',
        redPacletEvent: 'Red packet activity',
        gameBettingEvent: 'Game betting activity',
        pinduoduoEvent: 'Invitation activities',
        rankingListEvent: 'Ranking activity',
        registerRewardsEvent: 'Registration Activity',
        VIPWeeklyReward: 'VIP Weekly Rewards',
        VIPMonthlyReward: 'VIP Monthly Rewards',
        VIPLevelUpReward: 'VIP level reward',
        Redeemyour: 'Redeem your bonus code below.',
        Redeem: 'Redeem',
        Unlocked: 'Unlocked',
        RechargeAmount: 'Recharge amount'
    },
    lossSupport: {
        title: 'Loss Support',
        lossSupport: 'Support',
        yesterdayLoss: "Yesterday's losses",
        todayBonus: "Today's Support Bonus",
        loseMoney: 'Amount of loss',
        extraprizes: 'Extra prizes',
        amountDamage: 'amount of damage',
        AmountReward: 'Amount of reward',
        EventIntro: 'Event Instructions：',
        VIPLevel: 'VIP level'
    },
    rebate: {
        title: 'Rebates on bets',
        BettingVolume: 'Betting volume',
        RewardAmount: 'Reward amount',
        EventIntro: 'Event Instructions：'
    },
    downApp: {
        downAppSuccTips: 'You have successfully installed the application.',
        downAppSuccTips2: 'You have already installed the application. Or click the download button icon in the upper right corner of the browser URL address to reinstall.',
        SaveVersionDesktop: 'Save web version to desktop',
        SaveVersionTips: 'Click to quickly download the application to your desktop',
        QuickVersion: 'Quick version',
        NormalVersion: 'Normal version',
        download: 'Download',
        NotInDevice: 'Not compatible with this device',
        Games: 'Games',
        Apps: 'Apps',
        Movies: 'Movies',
        Books: 'Books',
        Kids: 'Kids',
        ProductMadness: 'Product Madness',
        InAppPurchases: 'In-app purchases',
        reviews: 'reviews',
        Downloads: 'Downloads',
        Install: 'Install',
        Share: 'Share',
        AddToWishlist: 'Add to wishlist',
        appAvailableDevice: 'This app is available for your device',
        AboutGame: 'About this game',
        comment1: 'Experience the thrill of cards and the excitement of high-stakes gambling in gd-goatbet! Join a vibrant community of card enthusiasts in this immersive and social card game. Play Teen Patti in various modes, engage in thrilling tournaments, and rise to become the ultimate gd-goatbet. With its authentic gameplay, social features, and competitive spirit, gd-goatbet offers a captivating card-playing experience that will keep you coming back for more.',
        comment2: '1.Authentic Teen Patti Experience: Immerse yourself in the authentic and traditional Teen Patti card game, featuring realistic rules and engaging gameplay.',
        comment3: '2.Diverse Game Modes: Explore a variety of game modes, from classic Teen Patti to innovative variations, providing a dynamic and entertaining gaming experience.',
        comment4: '3.Social Card Gaming: Connect with friends, join clubs, and engage in multiplayer matches, fostering a social and interactive gaming environment.',
        DataSafety: 'Data safety',
        comment5: 'Safety starts with understanding how developers collect and share your data. Data privacy and security practices may vary based on your use, region, and age. The developer provided this information and may update it over time.',
        comment6: 'This app may share these data types with third parties',
        comment7: 'Location,Personal info and 6 others',
        comment8: 'This app may collect these data types',
        comment9: 'Location, Personal info and 6',
        comment10: 'Data is encrypted in transit',
        comment11: 'You can request that data be deleted',
        SeeDetails: 'See details',
        RatingsAndReviews: 'Ratings and reviews',
        Phone: 'Phone',
        Tablet: 'Tablet',
        October: 'October',
        comment12: "Great choice of popular games that are also in the casinos live, and lots of fun to play! Watching ads alone can sustain play, but can be agonizingly slow at times. Be prepared to make purchases or have lots of patience. Sometimes, you have to watch a 30 second to a minute long ad in between a few hands of play, and that can go on for several hands. On some games, you can't complete the bonus requirements unless you buy money to play. STILL, my favorite of all the casino apps to play!",
        comment13: '3,394 people found this review helpful',
        comment14: 'Did you find this helpful?',
        Yes: 'Yes',
        No: 'No',
        September: 'September',
        comment15: 'I really enjoy this game. It has a variety of different slots to play. I can earn extra coins as I play. Not many ads to watch. The amount of coins when buying is lower than other games, but due to the quality of this game, they are worth it in my opinion. I have downloaded and then deleted other apps after playing them.',
        comment16: '1,110 people found this review helpful',
        comment17: 'Did you find this helpful?',
        WhatNew: "What's new",
        comment18: 'Get our NEW games in our latest update!',
        comment19: 'For the best experience and most FUN we’ve squashed bugs and optimized your game.',
        AppSupport: 'App support',
        SimilarGames: 'SimilarGames',
        LightningLinkCasinoSlots: 'Lightning Link Casino Slots',
        QuickHitCasinoSlotGames: 'Quick Hit Casino Slot Games',
        SciPlay: 'SciPlay',
        LuckyTimeSlots: 'Lucky Time Slots',
        DGNGames: 'DGN Games',
        CashTornadoSlotsCasino: 'Cash Tornado™ Slots - Casino',
        ZerooGravityGames: 'Zeroo Gravity Games',
        GoldFishCasinoSlot: 'Gold Fish Casino Slot',
        FlagInappropriate: 'Flag as inappropriate',
        GooglePlay: 'Play',
        PlayPass: 'Play Pass',
        PlayPoints: 'Play Points',
        GiftCards: 'Gift cards',
        Redeem: 'Redeem',
        RefundPolicy: 'Refund policy',
        KidsFamily: 'Kids  family',
        ParentGuide: 'Parent Guide',
        FamilySharing: 'Family sharing',
        TermsService: 'Terms of Service',
        Privacidade: 'Privacidade',
        SobreGooglePlay: 'Sobre o Play',
        Desenvolvedores: 'Desenvolvedores',
        GoogleStore: 'Store',
        UnitedStates: 'United States (English)',

        AppStore: 'App Store',
        DesignedIPhone: 'Designed for iPhone',
        get: 'get',
        iPhoneScreenshots: 'iPhone Screenshots',
        AppPrivacy: 'App Privacy',
        intro1: 'The developer, ',
        intro2: ', indicated that the app’s privacy practices may include handling of data as described below. For more information, see the ',
        intro3: 'developer’s privacy policy',
        intro4: 'Data Not Collected',
        intro5: 'The developer does not collect any data from this app.',
        intro6: 'Privacy practices may vary, for example, based on the features you use or your age. ',
        intro7: 'Information',
        Seller: 'Seller',
        Size: 'Size',
        Category: 'Category',
        Compatibility: 'Compatibility',
        iPhone: 'iPhone',
        support1: 'Requires iOS 10.0 or later.',
        iPodTouch: 'iPod touch',
        support2: 'Requires iOS 10.0 or later.',
        Mac: 'Mac',
        support3: 'Requires macOS 11.0 or later and a Mac with Apple M1 chip or later.',
        Languages: 'Languages',
        EnglishSimplifiedChinese: 'English, Simplified Chinese',
        AgeRating: 'Age Rating',
        UnrestrictedWebAccess: 'Unrestricted Web Access',
        InfrequentMildHorrorFearThemes: 'Infrequent/Mild Horror/Fear Themes',
        Copyright: 'Copyright',
        Price: 'Price',
        Free: 'Free',
        PrivacyPolicy: 'Privacy Policy',
        Supports: 'Supports',
        GameCenter: 'Game Center',
        GameCenterTips: 'Challenge friends and check leaderboards and achievements.',
        Morewaysshop: 'More ways to shop',
        FindAppleStore: 'Find an Apple Store',
        or: 'or',
        otherRetailer: 'other retailer',
        nearYou: 'near you. ',
        OrCall: 'Or call 1-800-MY-APPLE.',
        ChooseYourCountryRegion: 'Choose your country or region',
        TermsUse: 'Terms of Use',
        SalesRefunds: 'Sales and Refunds',
        Legal: 'Legal',
        SiteMap: 'Site Map',
        ClickButtonToDownload: 'Download the app for safer withdrawals',
        ClickButtonToDownload2: 'Download the app to play games'
    },
    event: {
        Detail: 'Detail',
        greatBonus: 'Great Bonus For ',
        everyDeposit: 'Every Deposit',
        firstDeposit: 'First Deposit',
        secondDeposit: 'Second Deposit',
        thirdDeposit: 'Third Deposit',
        upTo: 'Up to',
        allPlayers: 'Available for all players ',
        before: 'Before',
        Bonus: 'Bonus',
        Deposit: 'Deposit'
    },
    person: {
        Bets: 'Bets',
        Balance: 'Balance',
        Bonus: 'Bonus',
        Transaction: 'Transaction',
        History: 'History',
        Welcome: 'Welcome to ',
        signIn: 'Please Sign up or Sign in first',
        invite: 'Invite friends',
        redPacket: 'Red Packet',
        Notification: 'Notification',
        Promotion: 'Promotion',
        VIPClub: 'VIP Club',
        LiveChat: 'Live Chat',
        AccountSettings: 'Account settings',
        Install: 'Install',
        BonusTransfer: 'Bonus transfer',
        transferMsg: 'The bonus is insufficient.',
        transferTips1: 'Do you want to transfer ',
        transferTips2: ' bonus to balance?',
        transferTips3: 'Transfer now'
    },
    Vip: {
        VIPSupport: 'VIP support',
        LevelBenefits: 'VIP Level & Benefits',
        Deposits: 'Deposits',
        Bets: 'Bets',
        or: 'or',
        to: 'to',
        joinClub: 'Join the VIP Club. Unlock High Cashbacks.All kinds of exquisite gifts.It will surprise you!',
        levelUp: 'Level Up to VIP',
        Steps: 'Steps',
        Benefits: 'Benefits',
        benefits1: 'Level up bonus',
        benefits2: 'Weekly bonus',
        benefits3: 'Monthly bonus',
        info1: 'VIP level up to get bonus!',
        info2: 'Claim free bonus every week.',
        info3: 'Claim free bonus every month.',
        More: 'More',
        promotionTip: 'Please see our promotions for more details.',
        Promotions: 'Promotions',
        FAQ: 'FAQ',
        question1: '1.What is "Level-Up Bonus"?',
        answer1: ' A "level-up bonus" is a VIP perk where rewards increase as you advance to higher VIP levels.',
        question2: '2.Benefits of upgrading VIP',
        answer2: 'Upgrading your VIP can get higher withdrawal amount and lower withdrawal fee!',
        LevelUpBonus: 'Level up bonus',
        WeeklyBonus: 'Weekly bonus',
        MonthlyBonus: 'Monthly Bonus',
        Level: 'Level',
        RequiredDeposit: 'Required Deposit',
        RequiredBet: 'Required Bet',
        Get: 'Get',
        UpgradeBonus: 'Upgrade bonus',
        vipRewards: 'Sign Up & Get Exclusive VIP Rewards!',
        earnPoints: 'Bet and earn points!',
        levelUpBenifits: 'Unlock Rewards, Level Up Your Benefits!',
        Step1: 'Step1',
        Step2: 'Step2',
        Step3: 'Step3'
    },
    MyProfile: {
        Profile: 'Profile',
        ID: 'ID',
        Join: 'Join',
        TotalBet: 'Total Bet',
        TotalBonus: 'Total Bonus',
        FavoriteGames: 'Favorite Games'
    },
    Transaction: {
        Transaction: 'Transaction',
        TotalAmount: 'Total Amount',
        DepositValue: 'Deposit value',
        Bonus: 'Bonus',
        Received: 'Received',
        WithdrawBonus: 'Withdraw bonus',
        Rate: 'Rate',
        Today: 'Today',
        Yesterday: 'Yesterday',
        LastThreeDays: 'Last 3 days',
        LastSevenDays: 'Last 7 days',
        LastFifteenDays: 'Last 15 days',
        LastThiryDays: 'Last 30 days',
        AllState: 'All State',
        Processing: 'Processing',
        Successful: 'Successful',
        Failed: 'Failed',
        WithdrawFail: 'Withdraw Fail',
        Withdrawing: 'Withdrawing',
        WithdrawSuccess: 'Withdraw Success',
        PaySuccess: 'Pay Success',
        Paying: 'Paying',
        Fail: 'Fail',
        WaitPay: 'WaitPay',
        Cancel: 'Cancel'
    },
    BetsHistory: {
        BetsHistory: 'Bets History'
    },
    Setting: {
        Setting: 'Setting',
        LiveChat: 'Live Chat ',
        AccountID: 'Account ID',
        PhoneNumber: 'Phone Number',
        Password: 'Password',
        Change: 'Change',
        oldPasswordTips: 'enter the original password',
        PleaseEnterYourOriginalPassword: 'Please enter your original password',
        passwordTips: 'Password(6-16)',
        PleaseEnterYourNewPassword: 'Please enter your new password',
        passwordTips2: 'Enter password again',
        PleaseEnterYourNewPasswordSure: 'Please enter your new password again',
        changePwd: 'Change password'
    },
    Wallet: {
        Wallet: 'Wallet',
        TotalBalance: 'Total Balance',
        Cash: 'Cash',
        safeFunds: 'Funds are very safe',
        tip1: 'is a formal online gambling platform.',
        tip2: 'We accept government supervision, so we have higher credibility and better safety.',
        tip3: 'Please bet with peace of mind, your funds will be guaranteed the highest level from beginning to end.'
    },
    RedPacket: {
        RedPacket: 'Red Packet',
        NextEvent: 'Next Event',
        Participate: 'Participate'
    },
    turntable: {
        lottery: 'Lottery',
        title: 'Jackpot Wheel of Fortune',
        totalTips1: 'Current lucky points ',
        totalTips2: 'You still need to bet ',
        totalTips3: ' to get ',
        totalTips4: ' Lucky Points',
        totalTips5: 'Daily first charge can earn ',
        totalTips6: ' lucky points',
        Silver: 'Silver',
        Gold: 'Gold',
        Diamond: 'Diamond',
        tab1: 'Report',
        tab2: 'My Reference',
        JustRemoved: 'just pulled',
        drawTitle1: 'Congratulations',
        drawTitle2: 'unfortunately',
        drawContent1: 'obtain',
        drawContent2: 'No reward received',
        cashOut: 'Cash Out',
        InviteFriends: 'Invite friends to get Money',
        nextFreeSpin: 'Next Free Spin:',
        targetAmount: 'Target Amount:',
        withdrawSuccessfully: 'Withdraw successfully',
        downTimeTips1: 'After',
        downTimeTips2: 'seconds,',
        downTimeTips3: 'the accumulated bonus and lottery times will be cleared'
    },
    winRankList: {
        RankingList: 'Ranking List',
        Ranking: 'Ranking',
        Rules: 'Rules',
        MyRewards: 'My rewards',
        ActivityAward: 'Activity award:',
        Rank: 'Rank',
        Daily: 'Daily',
        Weekly: 'Weekly',
        Monthly: 'Monthly',
        WeeklyList: 'Weekly list',
        MonthlyList: 'Monthly list',
        TotalBets: 'Total Bets',
        Reward: 'Reward:',
        NoRank: 'No rank',
        MyBets: 'My Bets',
        RanksLeft: 'Ranks left',
        MyReward: 'My Reward',
        History: 'History',
        LastDay: 'Last Day',
        LastWeek: 'Last Week',
        LastMonth: 'Last Month',
        Rewards: 'Rewards',
        PhoneNo: 'Phone No.'
    },
    footer: {
        Promo: 'Promo',
        VIPClub: 'VIP Club',
        Promotions: 'Promotions',
        ReferAndEarn: 'Refer and Earn',
        Bonus: 'Bonus',
        AboutUs: 'About Us',
        About: 'About',
        DataProtectionPolicy: 'Data Protection Policy',
        BusinessContinuityPolicy: 'Business Continuity Policy',
        Help: 'Help',
        PrivacyPolicy: 'Privacy Policy',
        ServiceTerms: 'Service Terms',
        GamblingAddictionWarnings: 'Gambling Addiction Warnings',
        GamblingTreatmentCenters: 'Gambling Treatment Centers',
        ResponsibleGambling: 'Responsible Gambling',
        ContactUs: 'Contact  Us',
        JoinOurGroup: 'Join our group',
        ContactViaTelegram: 'Contact via Telegram',
        OnlineServer: 'Online Server',
        JoinourCommunity: 'Join our Community',
        tips1: '1.This product is for use by users over the age of 18 and is intended for entertainment purposes only.',
        tips2: '2.This game contains in-app purchases. ',
        tips3: '3.The fact that a player plays or wins in a social gambling game does not mean which he will win on real money bets and related games in the future.'
    },
    treasureChest: {
        title: 'Recommend friends to open the treasure chest',
        save: 'Click to Save',
        ReferralLink: 'Referral link',
        directSubordinates: 'Direct subordinates',
        ReferralCode: 'Referral Code',
        chestInfoTips1: 'What is an effective subordinate? (meets all requirements)',
        chestInfoTips2: 'Subordinates completed a total of ',
        chestInfoTips3: ' first refills',
        chestInfoTips4: 'Subordinate accumulator bets of ',
        chestInfoTips5: ' or more',
        People: 'People',
        People2: 'People',
        People3: 'People',
        commissionAccount: 'Commission Account',
        todayCommittee: 'The committee released today',
        historyCommittee: 'Published historical commissions',
        obtain: 'obtain',
        inviteTitle: 'Obtained by inviting valid subordinates',
        highestRebate: 'Highest rebate',
        commissionBalance: 'Commission balance',
        totalCommissionIncome: 'Total commission income',
        EstimatedCommissionToday: 'Estimated commission today',
        YesterdayCommittee: 'Yesterday committee',
        register: 'Register',
        firstChargeMark: 'First charge mark',
        firstFee: 'First fee',
        chargeAmount: 'Charge amount',
        totalHistoricalData: 'Total historical data',
        firstLevelSubordinateData: 'First level subordinate data',
        secondarySubordinateData: 'Secondary subordinate data',
        thirdLevelSubordinateData: 'Third level subordinate data',
        rewardRatio: 'Reward rate',
        collectRebates: 'Recharge rewards bonus',
        teamCollection: 'Recharge',
        firstLevelCommission: 'First level commission:',
        secondLevelCommission: 'Second level commission:',
        thirdLevelCommission: 'Third level commission:',
        firstLevelBetting: 'First level Betting:',
        secondLevelBetting: 'Second level Betting:',
        thirdLevelBetting: 'Third level Betting:'
    },
    Pending: {
        Pending: 'Pending',
        Hour: 'Hour',
        Type: 'Type',
        Bonus: 'Bonus',
        Limit: 'Limit',
        EndTime: 'End Time',
        All: 'All',
        DailyAttendance: 'Daily Attendance',
        TimedRedEnvelope: 'Timed red envelope',
        LuckyTurntable: 'Lucky turntable',
        ReliefMoney: 'Relief Money',
        RegisterRewards: 'Register Rewards',
        Other: 'Other'
    },
    depositTotal: {
        title: 'Accumulated recharge rewards',
        AccumulatedRecharge: 'Accumulated recharge',
        GetRewards: 'Get rewards',
        EventIntro: 'Event Instructions：'
    },
    depositRepeat: {
        full: 'full',
        RegisterFirstCharge: 'Register First Charge',
        DailyFirstCharge: 'Daily first charge',
        EachRecharge: 'Each recharge',
        give: 'give',
        thRecharge: 'th recharge',
        EveryRecharge: 'Every recharge',
        EventIntro: 'Event Instructions：'
    }
}
