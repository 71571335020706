<template>
    <div class="popup" :class="classObj">
        <van-popup v-model="show" @click-overlay="close">
            <div class="icon-close" @click="close">
                <i class="iconfont icon-s-cuowu-guanbi"></i>
            </div>
            <div class="content">
                <div class="header">
                    <div class="title">
                        <div class="img">
                            <img src="@/assets/deposit/newDeposit.png" />
                        </div>
                        <div>{{ $t("deposit.information") }}</div>
                    </div>
                    <!-- <div class="desc">{{ $t("deposit.DepositTips") }}</div> -->
                </div>
                <div class="main-content">
                    <div class="descBar">{{ $t("deposit.TransferAmount") }} <span class="importVal" @click="onCopy(amount)">{{ amount
                            }}</span>
                        <br />
                        {{ $t("deposit.TargetAccount") }} <span class="importVal" @click="onCopy(mobileNumber)">{{ mobileNumber
                            }}</span>
                        <br />
                        {{ $t("deposit.name") }} <span class="importVal" @click="onCopy(PayeeName)">{{ PayeeName
                            }}</span>
                        <br />
                        {{ $t("deposit.bank") }} <span class="importVal" @click="onCopy(bankName)">{{ bankName
                            }}</span>
                        <br />
                        {{ $t("deposit.showTips") }}
                    </div>
                    <div class="btn" @click="close">{{ $t("deposit.transferred") }}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getUrlMsg } from '@/api/deposit'
export default {
    name: 'payDetail',
    data() {
        return {
            show: false,
            amount: '500',
            mobileNumber: '123456789',
            PayeeName: 'Зульфа Султанов Рафисович',
            bankName: 'А-Мобайл'
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        }
    },
    created() {
        this.amount = 250
    },
    methods: {
        close() {
            this.show = false
        },
        setMsg(val) {
            getUrlMsg({ getUrl: val }).then(res => {
                this.amount = res.payment.amount / 100
                this.mobileNumber = res.sbp.phone
                this.PayeeName = res.sbp.name
                this.bankName = res.sbp.bank
                this.open()
            })
        },
        // async getUrlMsg(url) {
        //     try {
        //         const response = await fetch(url)
        //         const data = await response.json()
        //         // console.log('data: ', data)
        //         this.amount = data.payment.amount / 100
        //         this.mobileNumber = data.sbp.phone
        //         this.PayeeName = data.sbp.name
        //         this.bankName = data.sbp.bank
        //         this.open()
        //     } catch (error) {
        //         console.error('fail:', error)
        //     }
        // },
        open() {
            this.show = true
        }
    }
}
</script>

<style scoped lang="scss">
.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .icon-close {
            width: 28px;
            height: 28px;
            background: rgba(0, 0, 0, .4);
            border-radius: 50px;
            font-weight: 700;
            box-sizing: border-box;
            position: absolute;
            top: 10px;
            right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            cursor: pointer;

            &>i {
                color: #fff;
                font-size: .22rem;
                vertical-align: middle;
            }
        }

        .content {
            border-radius: 12px;
            background-color: rgb(245 248 250);
            width: 500px;
            max-height: 80vh;
            height: 700px;
            box-sizing: border-box;
            position: relative;
            overflow: auto;
            padding-bottom: 30px;
        }

        .header {
            height: 118px;
            background: linear-gradient(90deg, #b3ddf2, #b2e5ae);
            width: 100%;
            border-radius: 12px 12px 0 0;
            overflow: hidden;

            .title {
                height: 110px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 24px;
                color: #000000e6;
                font-weight: bold;

                &>.img {
                    width: 110px;
                    height: 100px;
                    padding: 15px;
                    margin-right: 15px;

                    &>img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .desc {
                background-color: rgba(236, 240, 246, 0.6);
                border-radius: 10px;
                margin: 0 12px;
                // margin-top: 15px;
                height: 130px;
                padding: 20px 2%;
                text-align: center;
                font-size: .28rem;
                color: #4d4d4d;
            }
        }

        .main-content {
            padding: 0 .28rem;

            .importVal {
                color: #ff0000;
                text-decoration: underline;
            }

            .descBar {
                color: #000000;
                font-size: 24px;
                margin-top: 50px;
                text-align: left;
            }

            .btn {
                display: block;
                width: 90%;
                margin: 4% auto 7%;
                line-height: 40px;
                font-size: 14px;
                margin-top: 20px;
                font-weight: bold;
                color: #8f310f;
                text-align: center;
                text-decoration: none;
                background: linear-gradient(180deg, #fff7bc 0%, #ffe766 44.79%, #ffb932 53.65%, #ffcf54 94.9%, #ffebb9 99.58%);
                border-radius: 5px;
                box-sizing: border-box;
            }

            .table {
                width: 100%;
                border: .01rem solid #388e3c;
                margin-bottom: .2rem;

                .table-header {
                    background: #388e3c;
                    color: #ffd700;
                    text-align: center;
                    width: 100%;
                    display: flex;
                    align-items: center;

                    &>div:first-child {
                        height: 100%;
                        width: 70%;
                        padding: 10px;
                    }

                    &>div:last-child {
                        height: 100%;
                        width: 30%;
                        padding: 10px;
                    }
                }

                .table-body {
                    width: 100%;

                    &>.tr {
                        display: flex;
                        align-items: center;
                        border-bottom: .01rem solid #388e3c;

                        &>div:first-child {
                            height: 100%;
                            width: 70%;
                            border-right: .01rem solid #388e3c;
                            padding: 10px;
                        }

                        &>div:last-child {
                            height: 100%;
                            width: 30%;
                            padding: 10px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }

            .how {
                padding-bottom: 38px;

                .how-title {
                    font-weight: bold;
                    padding: 5px 2%;
                    text-align: center;
                    box-sizing: border-box;
                    color: #4d4d4d;
                }

                .how-step {
                    font-weight: bold;
                    background: linear-gradient(180deg, #fff613 0%, #fe9800 50%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: center;
                    margin: 10% 0px 6% 0px;
                }

                &>img {
                    box-sizing: border-box;
                    height: auto;
                    max-width: 100%;
                }

                .how-tips {
                    font-weight: bold;
                    padding: 0px 2%;
                    text-align: center;
                    color: #4d4d4d;
                }
            }
        }

    }

    &.mobile {
        // width: 100% !important;
        // height: 100% !important;

        .van-popup {
            &.van-popup--center {
                top: 16vh;
                bottom: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                // height: 100% !important;
            }
        }

        .content {
            width: 100% !important;
            height: 100% !important;
            max-height: 100%;
            border-radius: 12px 12px 0 0 !important;
        }
    }
}
</style>
