import request from '@/common/http/request'

// 获取分享信息
export function getShareinfo(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/member/getShareIndexData?timestamp=' + timestamp,
        method: 'post',
        data
    })
}
// 分享获取是否隐藏邀请链接
export function getHideShareStatus(data) {
    return request({
        url: '/sys/getHideStatus',
        method: 'get',
        data,
        isNoToastErr: true
    })
}

// 分享获取合伙人信息
export function getPartnerInfo(data) {
    return request({
        url: '/memberPartner/info',
        method: 'post',
        data
    })
}
