<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <statusBar></statusBar>
                <div class="header">
                    <div>{{ $t("Withdraw.Withdraw") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="first-Deposit"></div>
                <div class="WithdrawTypes">
                    <div class="WithdrawTypes__item" :class="{ 'act': item.value === WithdrawType }"
                        v-for="(item) in WithdrawTypes" :key="item.value" @click="changeWithdrawType(item)">
                        <i class="iconfont " :class="[item.icon]"></i>
                        <span>{{ item.name }}</span>
                    </div>
                </div>
                <div class="tab">
                    <div class="tab__item" :class="{ 'act': item.value === formData.balanceType }"
                        v-for="(item) in methodTypes" :key="item.value" @click="onChangeBalanceType(item)">
                        <span>{{ item.name }}</span>
                    </div>
                </div>
                <div v-if="WithdrawType == 'OnlineWithdraw'" class="tab_content">
                    <div v-if="formData.balanceType === 'GameBalance'" class="tab_content_box">
                        <div class="withdral_box">
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.WithdrawableBalance') }}</div>
                                <div class="val">{{ CurrencyType }}{{ userInfo.balanceAmtAccount ?
                                    userInfo.balanceAmtAccount.useBalance : 0 }}</div>
                            </div>
                            <div class="withdral_item_bot">
                                <div class="withdral_item">
                                    <div class="txt">{{ $t('Withdraw.WithidrawLimitPerday') }}</div>
                                    <div class="val">
                                        {{ CurrencyType }}{{ userInfo.levelDetailVo.useBalanceWithdrawLimit || 0 }}/{{
                                            CurrencyType }}{{ userInfo.levelDetailVo.withdrawLimit || 0 }}
                                    </div>
                                </div>
                                <div class="withdral_item">
                                    <div class="txt">{{ $t('Withdraw.WithdrawTimePerday') }}</div>
                                    <div class="val">
                                        {{ userInfo.levelDetailVo.useBalanceWithdrawNum || 0 }}/{{
                                            userInfo.levelDetailVo.withdrawNum || 0 }}
                                    </div>
                                </div>
                            </div>
                            <div class="withdraw_rate">
                                {{ $t('Withdraw.ratetxt1') }}
                                <span>{{ CurrencyType }}{{ rate }}</span>
                                {{ $t('Withdraw.ratetxt2') }}
                            </div>
                            <div v-if="userInfo && userInfo.memberDamaliangVoList && userInfo.memberDamaliangVoList.length">
                                <div class="withdraw_rate" v-for="(item,index) in userInfo.memberDamaliangVoList" :key="index">
                                    <span style="margin: 0;">{{ item.gameCategoryTypeList ? item.gameCategoryTypeList.join(','): '' }}</span>
                                    {{ $t('Withdraw.ratetxt3') }}
                                    <span style="margin: 0;">{{ CurrencyType }}{{ ((item.turnoverLimit || 0) - (item.orderWater || 0)).toFixed(2) }}</span>
                                    {{ $t('Withdraw.ratetxt4') }}
                                </div>
                            </div>
                        </div>
                        <div class="info_box">
                            <div class="withdral_input_item">
                                <div class="input_title">{{ $t('Withdraw.ChooseYourBankcard') }}</div>
                                <div class="page_input bank_input" @click.stop="handleAddBankCard">
                                    <img class="icon_bank" src="@/assets/withdraw/bg-pix.png" />
                                    <div
                                        :class="{ 'bankInput': bankData.accountName, 'bankInputNo': !bankData.accountName }">
                                        {{ bankData.accountName || $t('Withdraw.AddABankCardFirst') }}
                                    </div>
                                </div>
                            </div>
                            <div class="withdral_input_item">
                                <div class="input_title">
                                    {{ $t('Withdraw.WithdrawalAmount') }}
                                </div>
                                <div v-if="withdrawWay == 'immobilizationAmt' && withdrawAmtList.length"
                                    class="form_gap2">
                                    <div class="form_gap_item2_wrap"
                                        :class="{ 'active_gap_items2': item == formData.orderWithdrawAmt }"
                                        v-for="(item, index) in withdrawAmtList" :key="index"
                                        @click="formData.orderWithdrawAmt = item">
                                        <div class="num">{{ CurrencyType }} {{ item }}</div>
                                    </div>
                                </div>
                                <a-input v-else v-model="formData.orderWithdrawAmt" type="number">
                                    <div slot="prefix" class="unit">{{ CurrencyType }}</div>
                                </a-input>
                            </div>
                        </div>
                        <div class="withdral_box">
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.WithdrawAmount') }}</div>
                                <div class="val">{{ CurrencyType }} {{ formData.orderWithdrawAmt }}</div>
                            </div>
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.Fee') }}({{ FeeLabel }})</div>
                                <div class="val"
                                    v-if="Number(userInfo.levelDetailVo.minimumWithdrawal || 0) <= Number(formData.orderWithdrawAmt || 0)">
                                    {{ CurrencyType }} {{ FeeMoney }}</div>
                                <div class="val" v-else>{{ CurrencyType }} 0</div>
                            </div>
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.Receive') }}</div>
                                <div class="val"
                                    v-if="Number(userInfo.levelDetailVo.minimumWithdrawal || 0) <= Number(formData.orderWithdrawAmt || 0)">
                                    {{ CurrencyType }} {{ ReceiveMoney }}
                                </div>
                                <div class="val" v-else>{{ CurrencyType }} 0</div>
                            </div>
                        </div>
                        <div class="action_btn" @click="handleWithdraw">
                            {{ $t('Withdraw.Withdraw') }} {{ CurrencyType }}{{ formData.orderWithdrawAmt }}
                        </div>
                        <div v-if="withdrawDec" class="rulesDesc">
                            {{ withdrawDec }}
                        </div>
                        <div v-if="withdrawBalanceDec" class="rulesDesc">
                            {{ withdrawBalanceDec }}
                        </div>
                        <div v-if="withdrawLimtDec" class="rulesDesc">
                            {{ withdrawLimtDec }}
                        </div>
                        <div style="height: .3rem;"></div>
                    </div>
                    <div v-if="formData.balanceType === 'BonusBalance'" class="tab_content_box">
                        <div class="withdral_box">
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.WithdrawableBonus') }}</div>
                                <div class="val">
                                    {{ CurrencyType }}{{ userInfo.bonusAmtAccount ? userInfo.bonusAmtAccount.useBalance
                                        : 0
                                    }}
                                </div>
                            </div>
                            <div class="withdral_item_bot">
                                <div class="withdral_item">
                                    <div class="txt">{{ $t('Withdraw.WithdrawTimePerday') }}</div>
                                    <div class="val">
                                        {{ userInfo.levelDetailVo.useBonusWithdrawNum }}/{{
                                            userInfo.levelDetailVo.withdrawNum }}
                                    </div>
                                </div>
                                <div class="withdral_item">
                                    <div class="txt">{{ $t('Withdraw.WithidrawLimitPerday') }}</div>
                                    <div class="val">
                                        {{ CurrencyType }}{{ userInfo.levelDetailVo.useBonusWithdrawLimit }}/{{
                                            CurrencyType
                                        }}{{ userInfo.levelDetailVo.withdrawLimit }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="info_box">
                            <div class="withdral_input_item">
                                <div class="input_title">{{ $t('Withdraw.ChooseYourBankcard') }}</div>
                                <div class="page_input bank_input" @click.stop="handleAddBankCard">
                                    <img class="icon_bank" src="@/assets/withdraw/bg-pix.png" />
                                    <div
                                        :class="{ 'bankInput': bankData.accountName, 'bankInputNo': !bankData.accountName }">
                                        {{ bankData.accountName || $t('Withdraw.AddABankCardFirst') }}
                                    </div>
                                </div>
                            </div>
                            <div class="withdral_input_item">
                                <div class="input_title">{{ $t('Withdraw.WithdrawAmount') }}</div>
                                <a-input v-model="formData.orderWithdrawAmt" type="number">
                                    <div slot="prefix" class="unit">{{ CurrencyType }}</div>
                                    <div slot="suffix" class="All" @click.stop="inputAllWithdrawAmt">{{
                                        $t('Withdraw.All') }}</div>
                                </a-input>
                            </div>
                        </div>
                        <div class="withdral_box">
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.WithdrawAmount') }}</div>
                                <div class="val">{{ CurrencyType }} {{ formData.orderWithdrawAmt }}</div>
                            </div>
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.Fee') }}({{ FeeLabel }})</div>
                                <div class="val"
                                    v-if="Number(userInfo.levelDetailVo.minimumWithdrawal || 0) <= Number(formData.orderWithdrawAmt || 0)">
                                    {{ CurrencyType }} {{ FeeMoney }}</div>
                                <div class="val" v-else>{{ CurrencyType }} 0</div>
                            </div>
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.Receive') }}</div>
                                <div class="val"
                                    v-if="Number(userInfo.levelDetailVo.minimumWithdrawal || 0) <= Number(formData.orderWithdrawAmt || 0)">
                                    {{ CurrencyType }} {{ ReceiveMoney }}
                                </div>
                                <div class="val" v-else>{{ CurrencyType }} 0</div>
                            </div>
                        </div>
                        <div class="action_btn" @click="handleWithdraw">
                            {{ $t('Withdraw.Withdraw') }} {{ CurrencyType }}{{ formData.orderWithdrawAmt }}
                        </div>
                        <div style="height: .3rem;"></div>
                    </div>
                </div>
                <div v-if="WithdrawType == 'TransferWithdraw'" class="tab_content">
                    <div v-if="formData.balanceType === 'GameBalance'" class="tab_content_box">
                        <div class="withdral_box">
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.WithdrawableBalance') }}</div>
                                <div class="val">{{ CurrencyType }}{{ userInfo.balanceAmtAccount ?
                                    userInfo.balanceAmtAccount.useBalance : 0 }}</div>
                            </div>
                            <div class="withdral_item_bot">
                                <div class="withdral_item">
                                    <div class="txt">{{ $t('Withdraw.WithidrawLimitPerday') }}</div>
                                    <div class="val">
                                        {{ CurrencyType }}{{ userInfo.levelDetailVo.useBalanceWithdrawLimit || 0 }}/{{
                                            CurrencyType }}{{ userInfo.levelDetailVo.withdrawLimit || 0 }}
                                    </div>
                                </div>
                                <div class="withdral_item">
                                    <div class="txt">{{ $t('Withdraw.WithdrawTimePerday') }}</div>
                                    <div class="val">
                                        {{ userInfo.levelDetailVo.useBalanceWithdrawNum || 0 }}/{{
                                            userInfo.levelDetailVo.withdrawNum || 0 }}
                                    </div>
                                </div>
                            </div>
                            <div class="withdraw_rate">
                                {{ $t('Withdraw.ratetxt1') }}
                                <span>{{ CurrencyType }}{{ rate }}</span>
                                {{ $t('Withdraw.ratetxt2') }}
                            </div>
                            <div v-if="userInfo && userInfo.memberDamaliangVoList && userInfo.memberDamaliangVoList.length">
                                <div class="withdraw_rate" v-for="(item,index) in userInfo.memberDamaliangVoList" :key="index">
                                    <span style="margin: 0;">{{ item.gameCategoryTypeList ? item.gameCategoryTypeList.join(','): '' }}</span>
                                    {{ $t('Withdraw.ratetxt3') }}
                                    <span style="margin: 0;">{{ CurrencyType }}{{ ((item.turnoverLimit || 0) - (item.orderWater || 0)).toFixed(2) }}</span>
                                    {{ $t('Withdraw.ratetxt4') }}
                                </div>
                            </div>
                        </div>
                        <div class="info_box">
                            <div class="withdral_input_item">
                                <div class="input_title">{{ $t('Withdraw.USDTPaymentAddress') }}</div>
                                <a-input v-model="formData.cashAddress"
                                    :placeholder="$t('Withdraw.PleaseEnterUSDTPaymentAddress')" allowClear>
                                    <img slot="prefix" class="icon_Transfer" src="@/assets/deposit/icon_Transfer.png" />
                                </a-input>
                            </div>
                            <div class="withdral_input_item">
                                <div class="input_title">{{ $t('Withdraw.WithdrawAmount') }}</div>
                                <!-- <a-input v-model="formData.orderWithdrawAmt" type="number">
                                    <div slot="prefix" class="unit">{{ CurrencyType }}</div>
                                    <div slot="suffix" class="All" @click.stop="inputAllWithdrawAmt">{{
                                        $t('Withdraw.All') }}</div>
                                </a-input> -->
                                <div v-if="withdrawWay == 'immobilizationAmt' && withdrawAmtList.length"
                                    class="form_gap2">
                                    <div class="form_gap_item2_wrap"
                                        :class="{ 'active_gap_items2': item == formData.orderWithdrawAmt }"
                                        v-for="(item, index) in withdrawAmtList" :key="index"
                                        @click="formData.orderWithdrawAmt = item">
                                        <div class="num">{{ CurrencyType }} {{ item }}</div>
                                    </div>
                                </div>
                                <a-input v-else v-model="formData.orderWithdrawAmt" type="number">
                                    <div slot="prefix" class="unit">{{ CurrencyType }}</div>
                                    <div slot="suffix" class="All" @click.stop="inputAllWithdrawAmt">{{
                                        $t('Withdraw.All')
                                    }}</div>
                                </a-input>
                            </div>
                        </div>
                        <div class="withdral_box">
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.WithdrawAmount') }}</div>
                                <div class="val">{{ CurrencyType }} {{ formData.orderWithdrawAmt }}</div>
                            </div>
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.Fee') }}({{ FeeLabel }})</div>
                                <div class="val"
                                    v-if="Number(userInfo.levelDetailVo.minimumWithdrawal || 0) <= Number(formData.orderWithdrawAmt || 0)">
                                    {{ CurrencyType }} {{ FeeMoney }}</div>
                                <div class="val" v-else>{{ CurrencyType }} 0</div>
                            </div>
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.Receive') }}</div>
                                <div class="val"
                                    v-if="Number(userInfo.levelDetailVo.minimumWithdrawal || 0) <= Number(formData.orderWithdrawAmt || 0)">
                                    {{ CurrencyType }} {{ ReceiveMoney }}
                                </div>
                                <div class="val" v-else>{{ CurrencyType }} 0</div>
                            </div>
                        </div>
                        <div class="exchangeRate">
                            <div class="exchangeRate_left">
                                <div>{{ $t('deposit.ExchangeRate') }}</div>
                                <div>
                                    <span>U1 : {{ CurrencyType }}{{ changeRate }}</span>
                                    <span class="iconfont icon-shuaxin" @click="getRate"></span>
                                </div>
                            </div>
                            <div class="exchangeRate_right">
                                <div>{{ $t('Withdraw.Withdraw') }}</div>
                                <div>≈{{ rateMoney }}U</div>
                            </div>
                        </div>
                        <div class="action_btn" @click="handleWithdraw">
                            {{ $t('Withdraw.Withdraw') }} {{ CurrencyType }}{{ formData.orderWithdrawAmt }}
                        </div>
                        <div v-if="withdrawDec" class="rulesDesc">
                            {{ withdrawDec }}
                        </div>
                        <div v-if="withdrawBalanceDec" class="rulesDesc">
                            {{ withdrawBalanceDec }}
                        </div>
                        <div v-if="withdrawLimtDec" class="rulesDesc">
                            {{ withdrawLimtDec }}
                        </div>
                        <div style="height: .3rem;"></div>
                    </div>
                    <div v-if="formData.balanceType === 'BonusBalance'" class="tab_content_box">
                        <div class="withdral_box">
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.WithdrawableBonus') }}</div>
                                <div class="val">
                                    {{ CurrencyType }}{{ userInfo.bonusAmtAccount ? userInfo.bonusAmtAccount.useBalance
                                        : 0
                                    }}
                                </div>
                            </div>
                            <div class="withdral_item_bot">
                                <div class="withdral_item">
                                    <div class="txt">{{ $t('Withdraw.WithdrawTimePerday') }}</div>
                                    <div class="val">
                                        {{ userInfo.levelDetailVo.useBonusWithdrawNum }}/{{
                                            userInfo.levelDetailVo.withdrawNum }}
                                    </div>
                                </div>
                                <div class="withdral_item">
                                    <div class="txt">{{ $t('Withdraw.WithidrawLimitPerday') }}</div>
                                    <div class="val">
                                        {{ CurrencyType }}{{ userInfo.levelDetailVo.useBonusWithdrawLimit }}/{{
                                            CurrencyType
                                        }}{{ userInfo.levelDetailVo.withdrawLimit }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="info_box">
                            <div class="withdral_input_item">
                                <div class="input_title">{{ $t('Withdraw.USDTPaymentAddress') }}</div>
                                <a-input v-model="formData.cashAddress"
                                    :placeholder="$t('Withdraw.PleaseEnterUSDTPaymentAddress')" allowClear>
                                    <img slot="prefix" class="icon_Transfer" src="@/assets/deposit/icon_Transfer.png" />
                                </a-input>
                            </div>
                            <div class="withdral_input_item">
                                <div class="input_title">{{ $t('Withdraw.WithdrawAmount') }}</div>
                                <a-input v-model="formData.orderWithdrawAmt" type="number">
                                    <div slot="prefix" class="unit">{{ CurrencyType }}</div>
                                    <div slot="suffix" class="All" @click.stop="inputAllWithdrawAmt">{{
                                        $t('Withdraw.All') }}</div>
                                </a-input>
                            </div>
                        </div>
                        <div class="withdral_box">
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.WithdrawAmount') }}</div>
                                <div class="val">{{ CurrencyType }} {{ formData.orderWithdrawAmt }}</div>
                            </div>
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.Fee') }}({{ FeeLabel }})</div>
                                <div class="val"
                                    v-if="Number(userInfo.levelDetailVo.minimumWithdrawal || 0) <= Number(formData.orderWithdrawAmt || 0)">
                                    {{ CurrencyType }} {{ FeeMoney }}</div>
                                <div class="val" v-else>{{ CurrencyType }} 0</div>
                            </div>
                            <div class="withdral_item">
                                <div class="txt">{{ $t('Withdraw.Receive') }}</div>
                                <div class="val"
                                    v-if="Number(userInfo.levelDetailVo.minimumWithdrawal || 0) <= Number(formData.orderWithdrawAmt || 0)">
                                    {{ CurrencyType }} {{ ReceiveMoney }}
                                </div>
                                <div class="val" v-else>{{ CurrencyType }} 0</div>
                            </div>
                        </div>
                        <div class="exchangeRate">
                            <div class="exchangeRate_left">
                                <div>{{ $t('deposit.ExchangeRate') }}</div>
                                <div>
                                    <span>U1 : {{ CurrencyType }}{{ changeRate }}</span>
                                    <span class="iconfont icon-shuaxin" @click="getRate"></span>
                                </div>
                            </div>
                            <div class="exchangeRate_right">
                                <div>{{ $t('Withdraw.Withdraw') }}</div>
                                <div>≈{{ rateMoney }}U</div>
                            </div>
                        </div>
                        <div class="action_btn" @click="handleWithdraw">
                            {{ $t('Withdraw.Withdraw') }} {{ CurrencyType }}{{ formData.orderWithdrawAmt }}
                        </div>
                        <div style="height: .3rem;"></div>
                    </div>
                </div>
            </div>
        </van-popup>
        <tipsPopup ref="WithdrawTimePop">
            <div v-if="wTime1">{{ $t("Withdraw.notInTime1") }}{{ wTime1 + ' - ' + wTime2 }}</div>
            <div>{{ $t("Withdraw.notInTime2") }}{{ mTime1 + ' - ' + mTime2 }}</div>
            <div style="margin-top: .2rem;">{{ $t("Withdraw.notInTime3") }}</div>
        </tipsPopup>
        <tipsPopup ref="WithdrawSuccPop" :tips="withdrawSuccTips" @confirm="getUserInfo"></tipsPopup>
        <tipsPopup ref="WithdrawTipsPop" :tips="$t('Withdraw.WithdrawTips1')" :tips2="$t('Withdraw.WithdrawTips2')"></tipsPopup>
        <VerifyIdentityPopup ref="VerifyIdentityPopup" :form.sync="formData" @MaintenanceTime="MaintenanceTime"
            @WithDrawSucc="WithDrawSucc"></VerifyIdentityPopup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getUserInfo } from '@/api/user'
import { getRate } from '@/api/deposit'
import { getBankBrazil, getBankNigeria, getBankPakistan, getBankRussia, getWithdrawlist } from '@/api/withdraw'
import { Toast } from 'vant'
import { checkStr } from '@/utils'
import tipsPopup from './modules/tipsPopup.vue'
import VerifyIdentityPopup from './modules/VerifyIdentityPopup.vue'
import statusBar from '@/components/statusBar'
export default {
    name: 'withdrawPopup',
    components: { tipsPopup, VerifyIdentityPopup, statusBar },
    data() {
        return {
            WithdrawType: 'OnlineWithdraw',
            WithdrawTypes: [{
                name: this.$t('Withdraw.OnlineWithdraw'),
                key: 'OnlineWithdraw',
                value: 'OnlineWithdraw',
                icon: 'icon-shoujizhifu'
            }
            // {
            //     name: this.$t('Withdraw.TransferWithdraw'),
            //     key: 'TransferWithdraw',
            //     value: 'TransferWithdraw',
            //     icon: 'icon-USDT'
            // }
            ],
            methodTypes: [{
                name: this.$t('Withdraw.BalanceWithdraw'),
                key: 'BalanceWithdraw',
                value: 'GameBalance'
            },
            {
                name: this.$t('Withdraw.BonusWithdraw'),
                key: 'BonusWithdraw',
                value: 'BonusBalance'
            }
            ],
            bankData: {},
            userInfo: {
                levelDetailVo: {
                    rechargeFeeRate: 4,
                    amtOrder: 0,
                    currentAmtOrder: 0
                }
            },
            FeeLabel: '',
            rate: 0,
            formData: {
                balanceType: 'GameBalance',
                orderWithdrawAmt: '',
                withdrawPassword: '',
                code: '',
                cashAddress: ''
            },
            withdrawWay: 'noLimit',
            withdrawAmtList: [],

            canCallHandleWithDraw: true,

            wTime1: '', // 可提现开始时间
            wTime2: '', // 可提现结束时间
            mTime1: '', // 提现维护开始时间
            mTime2: '', // 提现维护结束时间

            withdrawSuccTips: '',

            changeRate: '',

            withdrawDec: '',
            withdrawBalanceDec: '',
            withdrawLimtDec: ''
        }
    },
    props: {
        isCanClose: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        show: {
            get() {
                return this.$store.getters.isShowWithdrawPopup
            },
            set(val) {
                return val
            }
        },
        FeeMoney() {
            let money = 0
            const levelDetailVo = this.userInfo.levelDetailVo
            if (this.formData.orderWithdrawAmt) money = Number((this.formData.orderWithdrawAmt * (levelDetailVo.rechargeFeeRate / 100)).toFixed(2)) + Number(levelDetailVo.rechargeFee || 0)
            return money
        },
        ReceiveMoney() {
            let money = 0
            if (this.formData.orderWithdrawAmt) money = (this.formData.orderWithdrawAmt - Number((this.formData.orderWithdrawAmt * (this.userInfo.levelDetailVo.rechargeFeeRate / 100)))).toFixed(2) - Number(this.userInfo.levelDetailVo.rechargeFee || 0)
            return money
        },
        rateMoney() {
            let money = 0
            if (this.ReceiveMoney) money = (Number(this.ReceiveMoney || 0) / Number(this.changeRate || 0)).toFixed(2)
            return money
        },
        isShowBankSetPopup() {
            return this.$store.getters.isShowBankSetPopup
        }
    },
    watch: {
        isShowBankSetPopup(newVal, oldVal) {
            if (!newVal) {
                this.getBank()
            }
        }
    },
    methods: {
        close() {
            this.$store.dispatch('user/setIsShowWithdrawPopup', false)
        },
        open() {
            this.getUserInfo()
            this.getBank()
            this.getWithdrawlist()
            this.methodTypes = [{
                name: this.$t('Withdraw.BalanceWithdraw'),
                key: 'BalanceWithdraw',
                value: 'GameBalance'
            },
            {
                name: this.$t('Withdraw.BonusWithdraw'),
                key: 'BonusWithdraw',
                value: 'BonusBalance'
            }
            ]
            this.$nextTick(() => { })
        },
        getUserInfo() {
            getUserInfo().then(res => {
                if (res.code) return
                const D = res.result
                this.userInfo = D
                const levelDetailVo = D.levelDetailVo
                this.FeeLabel = (levelDetailVo.rechargeFeeRate || 0).toFixed(2) + '%' + (levelDetailVo.rechargeFee ? ' + ' + Number(levelDetailVo.rechargeFee || 0) : '')
                const rate = ((D.turnoverLimit || 0) - (D.orderWater || 0)).toFixed(2)
                this.rate = rate > 0 ? rate : 0
            })
        },
        getRate() {
            getRate().then(res => {
                if (res.code) return
                this.changeRate = res.result
            })
        },
        changeWithdrawType(item) {
            this.WithdrawType = item.value
            this.formData.orderWithdrawAmt = ''
            this.formData.payChannelType = undefined
            if (this.WithdrawType === 'TransferWithdraw') {
                this.getRate()
            }
        },
        onChangeBalanceType(item) {
            this.formData.balanceType = item.value
            this.formData.orderWithdrawAmt = ''
        },
        async getBank() {
            let res = {}
            if (this.BelongNation === 'Pakistan') {
                res = await getBankPakistan({}, { custom: { toast: false } })
            } else if (this.BelongNation === 'Nigeria') {
                res = await getBankNigeria({}, { custom: { toast: false } })
            } else if (this.BelongNation === 'Russia') {
                res = await getBankRussia({}, { custom: { toast: false } })
            } else {
                res = await getBankBrazil({}, { custom: { toast: false } })
            }
            this.bankData = res.result || {}
            // if (!this.bankData.id) {
            //     this.handleAddBankCard()
            // }
        },
        getWithdrawlist() {
            getWithdrawlist().then((res) => {
                const D = res.result
                this.withdrawWay = D.withdrawWay // 提现金额方式
                this.withdrawAmtList = D.withdrawDetailAmtList // 提现金额列表
                this.withdrawDec = D.withdrawDec
                this.withdrawBalanceDec = D.withdrawBalanceDec
                this.withdrawLimtDec = D.withdrawLimtDec
                if (D.isUsdtWithDraw === 'Yes') {
                    this.WithdrawTypes = [{
                        name: this.$t('Withdraw.OnlineWithdraw'),
                        key: 'OnlineWithdraw',
                        value: 'OnlineWithdraw',
                        icon: 'icon-shoujizhifu'
                    },
                    {
                        name: this.$t('Withdraw.TransferWithdraw'),
                        key: 'TransferWithdraw',
                        value: 'TransferWithdraw',
                        icon: 'icon-USDT'
                    }]
                    this.$refs.WithdrawTipsPop.open()
                }
            })
        },
        handleAddBankCard() {
            this.$store.dispatch('user/setIsShowBankSetPopup', true)
        },
        inputAllWithdrawAmt() {
            const uInfo = this.userInfo
            const amt = this.formData.balanceType === 'GameBalance' ? (uInfo.balanceAmtAccount?.useBalance || 0) : (uInfo.bonusAmtAccount?.useBalance || 0)
            this.formData.orderWithdrawAmt = parseInt(amt)
        },
        handleWithdraw() {
            if (this.formData.balanceType === 'GameBalance' && this.rate > 0) {
                const tips = this.$t('Withdraw.ratetxt1') + ' ' + this.CurrencyType + this.rate + ' ' + this.$t('Withdraw.ratetxt2')
                Toast(tips)
                return
            }
            // 判断用户提现是否被禁用
            if ((this.userInfo && this.userInfo.teamWithdrawState && this.userInfo.teamWithdrawState === 'disable') || (this.userInfo && this.userInfo.withdrawState && this.userInfo.withdrawState === 'disable')) {
                Toast(this.$t('Withdraw.disabledWithdraw'))
                return false
            }
            // 判断用户提现金额是否小于最小提现金额
            if (this.formData?.orderWithdrawAmt < this.userInfo?.levelDetailVo?.minimumWithdrawal) {
                Toast(this.$t('Withdraw.PleaseEnterMiniWithdrawPrice'))
                return false
            }
            if (this.canCallHandleWithDraw) {
                this.canCallHandleWithDraw = false
                if (!this.formData.orderWithdrawAmt) {
                    Toast(this.$t('Withdraw.PleaseEnterWithdraw'))
                    this.canCallHandleWithDraw = true
                    return
                }
                if (!checkStr(this.formData.orderWithdrawAmt, 'integer')) {
                    Toast(this.$t('Withdraw.PleaseEnterInteger'))
                    this.canCallHandleWithDraw = true
                    return
                }
                if (this.WithdrawType !== 'TransferWithdraw' && !this.bankData.id) {
                    Toast(this.$t('Withdraw.PleaseChooseBank'))
                    this.canCallHandleWithDraw = true
                    return
                }
                if (this.WithdrawType === 'TransferWithdraw' && !this.formData.cashAddress) {
                    Toast(this.$t('Withdraw.PleaseEnterUSDTPaymentAddress'))
                    this.canCallHandleWithDraw = true
                    return
                }
                if (this.WithdrawType === 'TransferWithdraw') {
                    this.formData.payChannelType = 'USDTTRC20'
                }
                this.$refs.VerifyIdentityPopup.open()
                this.canCallHandleWithDraw = true
            }
        },
        WithDrawSucc(res) {
            this.$refs.WithdrawSuccPop.open()
            this.withdrawSuccTips = res.message
            this.getUserInfo()
        },
        MaintenanceTime(res) {
            this.$refs.WithdrawTimePop.open()
            const times = res.result
            const startTime = times.startTime
            const endTime = times.endTime
            const startTimeArr = startTime.split(':')
            const endTimeArr = endTime.split(':')
            let wStartTime = ''
            let wEndTime = ''
            if (endTimeArr[0] === '23' && endTimeArr[1] === '59' && startTimeArr[0] === '00' && startTimeArr[1] === '00') {
                this.mTime1 = startTime // 提现维护开始时间
                this.mTime2 = endTime // 提现维护结束时间
                return
            }
            if (endTimeArr[0] === '23' && endTimeArr[1] === '59') {
                endTimeArr[0] = '00'
                endTimeArr[1] = '00'
                endTimeArr[2] = '00'
            } else if (endTimeArr[1] === '59') {
                let hour = Number(endTimeArr[0])
                hour++
                hour = hour < 10 ? '0' + hour.toString() : hour
                endTimeArr[0] = hour
                endTimeArr[1] = '00'
                endTimeArr[2] = '00'
            } else {
                let minuto = Number(endTimeArr[1])
                minuto++
                minuto = minuto < 10 ? '0' + minuto.toString() : minuto
                endTimeArr[1] = minuto
            }
            wStartTime = endTimeArr.join(':')
            if (startTimeArr[0] === '00' && startTimeArr[1] === '00') {
                startTimeArr[0] = '23'
                startTimeArr[1] = '59'
                startTimeArr[2] = '00'
            } else {
                let minuto = Number(startTimeArr[1])
                minuto--
                minuto = minuto < 10 ? '0' + minuto.toString() : minuto
                startTimeArr[1] = minuto
            }
            wEndTime = startTimeArr.join(':')
            this.wTime1 = wStartTime // 可提现开始时间
            this.wTime2 = wEndTime // 可提现结束时间
            this.mTime1 = startTime // 提现维护开始时间
            this.mTime2 = endTime // 提现维护结束时间
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            border-radius: .2rem;
            width: 500px;
            max-height: 80vh;
            height: 700px;
            box-sizing: border-box;
            position: relative;
            overflow: auto;
            display: flex;
            flex-direction: column;
            overflow: auto;

            .header {
                display: flex;
                height: 1rem;
                padding-left: .46rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: .1rem;
                // background: #0F212E;

                &>div:first-child {
                    font-size: .32rem;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: #516382;
                    font-size: .36rem;
                    font-weight: bold;
                    height: 1rem;
                    width: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .first-Deposit {
                border-radius: 12px;
                height: 100px;
                background-image: url('@/assets/deposit/new_pay_banner_pc.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;
                margin: 0 .28rem;
            }

            .WithdrawTypes {
                height: .76rem;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                z-index: 3;
                cursor: pointer;
                border-bottom: .01rem solid #e5e7eb;
                margin: 0 .28rem;
                margin-top: .1rem;

                &__item {
                    text-align: center;
                    color: #B1BAD3;
                    font-size: .24rem;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: .3rem;
                    margin-right: .48rem;
                    position: relative;
                    text-align: left;
                    height: 100%;

                    &>i {
                        margin-right: .15rem;
                        font-size: .34rem;
                    }

                    &.act {
                        color: #ff7300;

                        &:after {
                            content: '';
                            width: 100%;
                            height: .04rem;
                            background-color: #ff7300;
                            position: absolute;
                            bottom: -.02rem;
                            left: 0;
                        }
                    }
                }
            }

            .tab {
                display: flex;
                gap: .14rem;
                padding: .12rem .28rem;
                border-bottom: .01rem solid #2F4553;
                margin: 0 .28rem;
                margin-top: .08rem;
                // background-color: #0F212E;

                &__item {
                    height: .58rem;
                    width: calc(50% - .12rem);
                    border-radius: .12rem;
                    font-weight: 500;
                    color: #fff;
                    padding: .12rem;
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: .25rem;
                    white-space: nowrap;
                    border: .01rem solid #2F4553;
                    text-align: center;
                    box-sizing: border-box;

                    .chose_icon {
                        width: .32rem;
                        height: .32rem;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        color: #1475E1;
                        border-bottom-right-radius: .1rem;
                        overflow: hidden;
                        font-size: .32rem;
                    }

                    &.act {
                        transition: all .3s;
                        color: #1475E1;
                        box-sizing: border-box;
                        border: .01rem solid #1475E1;
                    }
                }
            }

            .tab_content {
                width: 100%;
                flex: 1;
                position: relative;
                overflow: auto;

                .tab_content_box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    padding-bottom: 30px;

                    .withdral_box {
                        // border-bottom: .01rem solid #2F4553;
                        background: #17191f;
                        padding: .15rem .28rem;
                        margin: 0 .28rem;
                        margin-top: .2rem;
                        border-radius: .12rem;

                        .withdral_item {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            color: #687B88;
                            font-size: .26rem;
                            padding: .03rem 0;

                            .val {
                                color: #F59903;
                                font-size: .28rem;
                                margin-left: .3rem;
                            }
                        }

                        .withdraw_rate {
                            color: #687B88;
                            padding-top: .1rem;
                            font-size: .26rem;
                            text-align: left;
                            padding-bottom: 0;

                            &>span {
                                color: #1475E1;
                                margin: 0 .08rem;
                                font-size: .28rem;
                            }
                        }
                    }

                    .info_box {
                        .withdral_input_item {
                            padding: 0 .28rem;
                            padding-top: .08rem;

                            .icon_Transfer {
                                position: absolute;
                                width: .4rem;
                                height: .4rem;
                                top: calc(50% - .2rem);
                                left: 0
                            }
                        }

                        .input_title {
                            padding: .2rem 0;
                            font-size: .28rem;
                            color: #FFFFFF;
                            line-height: .36rem;
                            text-align: left;
                        }

                        .page_input {
                            width: 100%;
                            height: .8rem;
                            background-color: #000 !important;
                            border: .02rem solid #383e4b !important;
                            padding-left: .32rem;
                            position: relative;
                            display: flex;
                            align-items: center;
                            border-radius: .12rem;

                            &.bank_input {
                                padding-left: 1rem;
                                padding-right: .8rem;
                                cursor: pointer;

                                .icon_bank {
                                    position: absolute;
                                    width: .6rem;
                                    height: .6rem;
                                    top: calc(50% - .3rem);
                                    left: .18rem;
                                }

                                .bankInput {
                                    color: #fff;
                                }

                                .bankInputNo {
                                    color: #FE350D;
                                    font-weight: 400;
                                    font-size: .26rem;
                                }

                                &:after {
                                    content: '';
                                    width: .2rem;
                                    height: .2rem;
                                    border-top: .01rem solid #999A9D;
                                    border-right: .01rem solid #999A9D;
                                    transform: rotate(45deg);
                                    position: absolute;
                                    right: .32rem;
                                    top: .28rem;
                                }
                            }
                        }

                        ::v-deep input::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                        }

                        ::v-deep input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                        }

                        ::v-deep input[type='number'] {
                            -moz-appearance: textfield;
                        }

                        ::v-deep .ant-input {
                            background-color: #000 !important;
                            border-radius: 0.14rem;
                            font-size: 0.22rem;
                            height: .8rem;
                            padding: 0px 0.24rem !important;
                            color: #e3e3e3;
                            border: .02rem solid #383e4b !important;
                            padding-left: .7rem !important;

                            &:focus {
                                background-color: transparent;
                                border-color: $border-active-color !important;
                            }

                            &::-webkit-input-placeholder {
                                /* WebKit browsers */
                                font-size: .22rem;
                                color: #516382;
                                opacity: .7;
                                font-weight: 100;
                            }

                            &:-moz-placeholder {
                                /* Mozilla Firefox 4 to 18 */
                                font-size: .22rem;
                                color: #516382;
                            }

                            &::-moz-placeholder {
                                /* Mozilla Firefox 19+ */
                                font-size: .22rem;
                                color: #516382;
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10+ */
                                font-size: .22rem;
                                color: #516382;
                            }

                        }

                        ::v-deep .ant-input-prefix {
                            .unit {
                                color: #ff7300;
                                padding-right: .24rem;
                            }
                        }

                        ::v-deep .ant-input-suffix {
                            .ant-input-clear-icon {
                                padding-right: .2rem;
                            }

                            .All {
                                color: #ff7300;
                                padding-right: .24rem;
                                cursor: pointer;
                                padding: .1rem .24rem;
                            }
                        }

                        .form_gap2 {
                            display: flex;
                            gap: calc(8px * 4 / 3);
                            cursor: pointer;
                            position: relative;
                            flex-wrap: wrap;
                        }

                        .form_gap_item2_wrap {
                            width: calc(25% - 8px);
                            height: .8rem;
                            border-radius: .1rem;
                            box-sizing: border-box;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            color: #B1BAD3;
                            font-size: .24rem;
                            border: .01rem solid #2F4553;

                            .chose {
                                width: .32rem;
                                height: .32rem;
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                color: #1475E1;
                                border-bottom-right-radius: .1rem;
                                overflow: hidden;
                                font-size: .32rem;
                                display: none;

                                &.img {
                                    border: 0;
                                }
                            }

                            &.active_gap_items2 {
                                box-sizing: border-box;
                                border: .01rem solid #1475E1;

                                .num {
                                    color: #fff;
                                }

                                .chose {
                                    display: block;
                                }
                            }
                        }
                    }

                    .exchangeRate {
                        display: flex;
                        justify-content: space-between;
                        padding: .08rem 0;
                        margin: 0 .28rem;

                        .exchangeRate_left {
                            color: #666;
                            font-size: .22rem;
                            line-height: .3rem;
                            text-align: left;

                            &>div:last-child {
                                color: #fff;
                                display: flex;
                                align-items: center;
                                font-size: .22rem;
                                text-align: left;

                                &>span:last-child {
                                    color: #D1AE52;
                                    font-size: .3rem;
                                    margin-left: .12rem;
                                }
                            }
                        }

                        .exchangeRate_right {
                            text-align: right;
                            color: #FFAA09;
                            font-weight: 700;
                            font-size: .24rem;
                        }
                    }

                    .action_btn {
                        width: 6.94rem;
                        height: .7rem;
                        border-radius: .15rem;
                        margin: .3rem auto;
                        margin-top: .58rem;
                        font-family: eryawenrunti;
                        font-weight: 600;
                        font-size: .28rem;
                        color: #fff;
                        text-align: center;
                        line-height: .7rem;
                        cursor: pointer;
                        background-color: #ff7300;

                        &.disabled {
                            background: #9D9E9F;
                            color: rgba(255, 255, 255, 0.45);
                            cursor: not-allowed;
                            box-shadow: 0 .06rem 0 0 #2D3241, 0 .02rem .03rem 0 #333, 0 .02rem .03rem 0 #999;
                        }
                    }

                    .rulesDesc {
                        font-size: .24rem;
                        color: #afc2e4;
                        text-align: left;
                        margin: 0 .28rem;
                        margin-top: .32rem;
                        white-space: pre-line;
                    }
                }
            }
        }
    }

    &.mobile {
        // width: 100% !important;
        // height: 100% !important;

        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
            }
        }

        .content {
            width: 100% !important;
            height: 100% !important;
            padding-left: 0 !important;
            max-height: 100% !important;

            .first-Deposit {
                height: 1.52rem !important;
                padding-left: .45rem !important;
            }
        }
    }
}
</style>
